import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, QueryList, signal, ViewChildren } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, filter, Subject, switchMap, tap } from 'rxjs';
import { HotkeyDirective } from '@flow/directives/hotkeys/hotkey.directive';
import { SearchService } from '@flow/services/search/search.service';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TitleCasePipe } from '@angular/common';
import { SearchResultItemComponent } from '../search-result-item/search-result-item.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SiteWideSearch } from '@flow/types';
import { Router } from '@angular/router';

@Component({
    selector: 'app-search',
    imports: [
        FormsModule,
        MatIconButton,
        MatIcon,
        MatDialogContent,
        MatIconModule,
        HotkeyDirective,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        TitleCasePipe,
        SearchResultItemComponent,
        MatProgressSpinnerModule
    ],
    templateUrl: './search.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {

    private searchService = inject(SearchService);
    private _router = inject(Router);

    searchControl = new FormControl(''); // Reactive Form Control
    isSearching = signal(false); // Signal to indicate if a search is in progress
    private searchDelay = 300; // Delay in milliseconds
    searchResults: SiteWideSearch[] = []; // Store search results
    selectedIndex: number = -1; // Index of the selected search result

    @ViewChildren(SearchResultItemComponent)
    private itemComponents!: QueryList<SearchResultItemComponent>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public dialogRef: MatDialogRef<SearchComponent>,
                private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loadSearchHistory();
        this.setupSearchListener();
    }

    close(data: any = null): void {
        this.dialogRef.close(data);
    }

    resultsAreHistory(): boolean {
        // Check if the search results are from history
        return !this.searchControl.value?.trim()
    }

    resultSelected(result: SiteWideSearch): void {
       if (result.type === 'flowbot') {
            this.close({
                action: 'openFlowBot',
                query: this.searchService.cleanHtml(result.title)
            });
        } else {
            this.searchService.routeToSelected(result);
            this.close()
        }
        // Save the search result
        if (!this.resultsAreHistory()) {
            this.searchService.addSearch(result);
        }
    }

    // Group search results by type
    getGroupedResults(sort: boolean = true): { key: string; value: SiteWideSearch[] }[] {
        const groupedMap: { [key: string]: SiteWideSearch[] } = {};

        for (const result of this.searchResults) {
          if (!groupedMap[result.type]) {
            groupedMap[result.type] = [];
          }
          groupedMap[result.type].push(result);
        }

        // Convert to array and sort so that "flowbot" is first
        const entries = Object.entries(groupedMap).map(([key, value]) => ({ key, value }));

        if (sort) {
          // Move flowbot to the front
          entries.sort((a, b) => (a.key === 'flowbot' ? -1 : b.key === 'flowbot' ? 1 : 0));
        }

        return entries;
    }

    // Clear the search history
    clearHistory(): void {
        this.searchService.clearHistory();
        this.loadSearchHistory(); // Refresh the list
    }

    removeResult(result: SiteWideSearch): void {
        this.searchService.removeSearch(result);
        this.loadSearchHistory(); // Refresh the list
    }

    openFlowBotDialog(): void {
        const query = this.getCurrentSearchQuery();
        this.close({action: 'openFlowBot', query});
    }

    openFlowBotFullChat(): void {
        const query = this.getCurrentSearchQuery();
        const extras = query ? { queryParams: { query } } : {};
        this._router.navigate(['/flowbot/chat'], extras);
        this.close();
    }

    openNewTab() {
        const items = this.itemComponents.toArray();
        // Find the item that has focus
        const item = items.find((item) => item.hasFocus());
        if (item) {
            const result = item.result();
            this.searchService.routeToSelected(result, true); // Open in new tab
            this.searchService.addSearch(result); // Save the search
        }
    }

    clearSearch() {
        this.searchControl.setValue(''); // Clear the search input;
        this.searchResults = []; // Clear the search results;
        this.loadSearchHistory(); // Refresh the search history;
        this._changeDetectorRef.detectChanges();
    }

    navigate(action: 'down' | 'up') {
        if (action === 'down') {
            this.selectedIndex = Math.min(this.selectedIndex + 1, this.searchResults.length - 1);
        } else {
            this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
        }
        this.focusSelected();
    }

    private focusSelected() {
        const items = this.itemComponents.toArray();
        const selectedItem = items[this.selectedIndex];
        selectedItem?.focus();
    }

    // Load search history when the dialog opens
    private loadSearchHistory(): void {
        this.searchResults = this.searchService.getSearchHistory();
        // console.log('Search History:', this.searchResults);
    }

    // Listen for changes in the input field with debounce and switchMap
    private setupSearchListener(): void {
        this.searchControl.valueChanges
        .pipe(
            tap(() => this.isSearching.set(true)), // Set loading state
            debounceTime(this.searchDelay), // Wait before executing the search
            distinctUntilChanged(), // Ignore duplicate values
            filter(query => !!query && !!query.trim()), // Ignore empty strings
            switchMap((query) => this.searchService.performSearch(query)) // Cancel previous search and use the latest one
        )
        .subscribe((results: SiteWideSearch[]) => {
            // console.log('Results:', results);
            this.searchResults = results;
            this.isSearching.set(false); // Set loading state to false
            this._changeDetectorRef.detectChanges();
        });
    }

    private getCurrentSearchQuery(): string | undefined {
        const items = this.itemComponents.toArray();
        const focusedItem = items.find(item => item.hasFocus());
        let query = focusedItem?.result().title?.trim();
        if (!query) {
          query = this.searchControl.value?.trim();
        } else {
            query = this.searchService.cleanHtml(query);
            if (!this.resultsAreHistory()) {
                this.searchService.addSearch(focusedItem.result());
            }
        }
        // Return query only if it's not empty
        return query || undefined;
    }

}


