import gql from 'graphql-tag';
import { productFragment } from '../products/products.query';

export const factoriesFragment = gql`
    fragment FactoriesFields on Factories {
        factoryId
        title
        accountNumber
        phone
        email
        status
        shipTime
        freightTerms
        paymentTerms
        paysShipping
        baseCommission
        commissionDiscount
        overallDiscount
        discountType
        commissionPaymentTime
        leadTime
        additionalInformation
        externalPaymentTerms
        draft
        uuid
        alias
        insideRepID {
            id
            isAdministrator
            isInside
            isOutside
            firstName
            lastName
            email
            username
            status
        }
    }
`;

export const categoriesFragment = gql`
    fragment CategoriesFields on ProductCategory {
        uuid
        title
        commissionRate
    }
`;

export const findAllFactories = gql`
    {
        findAllFactories {
            ...FactoriesFields
        }
    }
    ${factoriesFragment}
`;

export const findRefinedFactories = gql`
    query ($page: Int, $perpage: Int, $sortField: String, $sortOrder: Int, $filters: [LandingPageFilter]){
        findRefinedFactories (page: $page, perpage: $perpage, sortField: $sortField, sortOrder: $sortOrder, filters: $filters){
            totalRows
            rows {
                factoryId
                title
                baseCommission
                freightTerms
                paymentTerms
                status
            }
        }
    }
`;

export const findFactoriesById = gql`
    query ($FactoryId: Int!){
        findFactoriesById(factoryId: $FactoryId){
            ...FactoriesFields
        }
    }
    ${factoriesFragment}
`;

export const deleteFactory = gql`
    mutation ($factoryId: Int!){
        deleteFactoryById(factoryId:$factoryId)
    }
`;

export const updateFactory = gql`
    mutation ($FactoryInput: FactoryInput!){
        updateFactoryById(input: $FactoryInput){
            ...FactoriesFields
        }
    }
    ${factoriesFragment}
`;

export const saveFactory = gql`
    mutation ($FactoryInput: FactoryInput!){
        saveFactory(input: $FactoryInput){
            ...FactoriesFields
        }
    }
    ${factoriesFragment}
`;

export const tempFactory = gql`
    query {
        findTempFactory{
            factoryId
        }
    }
`;

export const updateCategoryCommissionByFactory = gql`
    mutation ($factoryId: Int, $commissionRate: Float){
        updateCategoryCommissionByFactory(factoryId:$factoryId, commissionRate:$commissionRate) {
            ...CategoriesFields
        }
    }
    ${categoriesFragment}
`;

export const updateProductCommissionByFactory = gql`
    mutation ($factoryId: Int, $commissionRate: Float){
        updateProductCommissionByFactory(factoryId:$factoryId, commissionRate:$commissionRate) {
            ...ProductFields
        }
    }
    ${productFragment}
`;

export const isFactoryUsed = gql`
    query($factoryId: Int!){
        isFactoryUsed(factoryId:$factoryId)
    }
`;

export const saveFactoryAliases = gql`
    mutation ($factoryId: Int!, $alias: [String!]){
        saveFactoryAliases(factoryId: $factoryId, alias: $alias){
            uuid
            alias
        }
    }
`;
