import { Injectable } from '@angular/core';
import {Observable, switchMap, of, map, catchError, throwError} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from 'environments/environment';
import { CurrentUserPermission, PermissionByResource, UserPermissions } from './modules/admin/users/user.type';
import { Apollo } from 'apollo-angular';
import { findUserPermissions } from './app.query';


@Injectable({
	providedIn: 'root'
})
export class AppService {

	constructor(private _httpClient: HttpClient,
        private apollo: Apollo,
    ) { }

    getCurrentFlowVersion(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json', 'charset': 'UTF-8'
            })
        };
        return this._httpClient.get(`${environment.apiAutoPdf}/frontend/version`, httpOptions).pipe(
            switchMap((response: any) => {
                return of(response);
            }));
    }

    findUserPermissions(): Observable<UserPermissions> {
        return this.apollo
            .query<{ findUserPermissions: UserPermissions }>({
                query: findUserPermissions,
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => result.data.findUserPermissions
                ),
                catchError(error => throwError(error))
            );
    }


}
