import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {PermissionByResource, Privilege, SalesRepSelection, SalesRepSelectionInput, User} from './user.type';
import {Apollo} from 'apollo-angular';
import {catchError, map, tap} from 'rxjs/operators';
import {
    ARCHIVE_USER,
    DELETE_SALES_REP_SELECTION,
    FIND_ALL_DEFAULT_SELECTIONS,
    findAllRoles,
    findUserById,
    findUserByRole,
    findUserByTenant, GET_DEFAULT_SELECTION,
    getWPAutologinLink,
    SAVE_SALES_REP_SELECTION, UPDATE_SALES_REP_SELECTION, UPDATE_USER_TRANSACTIONS,
    updateUser,
    updateUserPassword,
    findResources,
    findPrivilegesByResource,
    findPrivileges,
    saveRoleResourceGrid
} from './users.query';
import {AuthService} from '../../../core/auth/auth.service';
import { NotificationSettings } from '../admin-settings/admin-settings.type';
import { updateNotifierSettings } from '../admin-settings/admin-settings.query';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _users: BehaviorSubject<User[]>;
    private _user: BehaviorSubject<User>;


    constructor(
        private apollo: Apollo,
        private _authService: AuthService
    ) {
        this._user = new BehaviorSubject(null);
        this._users = new BehaviorSubject(null);
    }
    // ---------------------------------------------------------------------------------------------------
    // @ Accessors
    // ---------------------------------------------------------------------------------------------------


    getUserById(id: string): Observable<User> {
        return this.apollo.query<{ findUserById: User }>({
            query: findUserById,
            variables: {
                'id': id,
            }
        }).pipe(map((result: any) => result.data.findUserById),
            tap((user) => {
                this._user.next(user);
            }));
    }

    findTenantUsers(): Observable<User[]> {
        return this.apollo
            .query<{ findUserByTenant: User[] }>({
                query: findUserByTenant,
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => result.data.findUserByTenant
                ),
                catchError(error => throwError(error))
            );
    }

    findUserByRole(role: string): Observable<User[]> {

        return this.apollo
            .query<{ findUserByRole: User[] }>({
                query: findUserByRole,
                variables: {
                    'roleName': role,
                },
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => {
                       return result.data.findUserByRole
                    }
                ),
                catchError(error => throwError(error))
            );
    }

    updateUserRole(user: User): Observable<User>{
        return this.apollo
            .mutate<{updateUser: User}>({
                mutation: updateUser,
                variables: {
                    input: user
                },
                fetchPolicy: 'no-cache'
            }).pipe(
                map( (result: any) => result.data.updateUser),
            )
    }

    updateUserPassword(userId: string, password: string, tenant): Observable<boolean>{
        return this.apollo
            .mutate<{updateUserPassword: boolean}>({
                mutation: updateUserPassword,
                variables: {
                    userId: userId,
                    password: password
                },
                fetchPolicy: 'no-cache',
            }).pipe(
                map((result: any) => true)
            )
    }

    getSupportUrl(): Observable<string> {
        return this.apollo
            .query<{ getWPAutologinLink: string }>({
                query: getWPAutologinLink,
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => result.data.getWPAutologinLink
                ),
                catchError(error => throwError(error))
            );
    }

    getDefaultSelection(customerId: number, factoryId: number): Observable<SalesRepSelection> {
        return this.apollo
            .query<{ getDefaultSelection: SalesRepSelection }>({
                query: GET_DEFAULT_SELECTION,
                variables: {
                    customerId: customerId,
                    factoryId: factoryId
                },
                fetchPolicy: 'no-cache'
            })
            .pipe(map((result) => result.data.getDefaultSelection));
    }

    findAllDefaultSelections(): Observable<SalesRepSelection[]> {
        return this.apollo
            .query<{ findAllDefaultSelections: SalesRepSelection[] }>({
                query: FIND_ALL_DEFAULT_SELECTIONS,
                fetchPolicy: 'no-cache'
            })
            .pipe(
                map((result) => {

                return result.data.findAllDefaultSelections
            }));
    }

    saveSalesRepSelection(input: SalesRepSelectionInput): Observable<SalesRepSelection> {
        return this.apollo
            .mutate<{ saveSalesRepSelection: SalesRepSelection }>({
                mutation: SAVE_SALES_REP_SELECTION,
                variables: { input },
                fetchPolicy: 'no-cache'
            })
            .pipe(map((result) => result.data.saveSalesRepSelection));
    }

    updateSalesRepSelection(input: SalesRepSelectionInput): Observable<SalesRepSelection> {
        return this.apollo
            .mutate<{ updateSalesRepSelection: SalesRepSelection }>({
                mutation: UPDATE_SALES_REP_SELECTION,
                variables: { input },
                fetchPolicy: 'no-cache'
            })
            .pipe(map((result) => result.data.updateSalesRepSelection));
    }

    deleteSalesRepSelection(id: string): Observable<boolean> {
        return this.apollo
            .mutate<{ deleteSalesRepSelection: boolean }>({
                mutation: DELETE_SALES_REP_SELECTION,
                variables: { id },
                fetchPolicy: 'no-cache'
            })
            .pipe(map((result) => result.data.deleteSalesRepSelection));
    }

    findResources(): Observable<PermissionByResource[]> {
        return this.apollo
            .query<{ findResources: PermissionByResource[] }>({
                query: findResources,
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => result.data.findResources
                ),
                catchError(error => throwError(error))
            );
    }

    findPrivilegesByResource(resource: string): Observable<PermissionByResource[]> {
        return this.apollo
            .query<{ findPrivilegesByResource: PermissionByResource[] }>({
                query: findPrivilegesByResource,
                variables: {
                    resource
                 },
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => result.data.findPrivilegesByResource
                ),
                catchError(error => throwError(error))
            );
    }

    findAllRoles(): Observable<string[]> {
        return this.apollo
            .query<{ findAllRoles: string[] }>({
                query: findAllRoles,
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => result.data.findAllRoles
                ),
                catchError(error => throwError(error))
            );
    }

    findPrivileges(): Observable<Privilege[]> {
        return this.apollo
            .query<{ findPrivileges: Privilege[] }>({
                query: findPrivileges,
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => result.data.findPrivileges
                ),
                catchError(error => throwError(error))
            );
    }

    updateRoutesPermissions(payload: PermissionByResource[]): Observable<PermissionByResource[]>{
        return this.apollo
            .mutate<{updateUser: PermissionByResource[]}>({
                mutation: saveRoleResourceGrid,
                variables: {
                    roleResources: payload
                },
                fetchPolicy: 'no-cache'
            }).pipe(
                map( (result: any) => result.data.saveRoleResourceGrid),
            )
    }

    saveNotificationSettings(userId: string, input: NotificationSettings[]): Observable<boolean> {
        return this.apollo
            .mutate<{ updateNotifierSettings: NotificationSettings[] }>({
                mutation: updateNotifierSettings,
                variables: {
                    userId,
                    notifiers: input
                },
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result) => {
                    const update = result.data.updateNotifierSettings;
                    return !!update;
                }),
                catchError(error => throwError(error))
            );
    }

    updateTransactions(oldUser: string, newUser: string, allTransactions: boolean, startDate: string, endDate: string): Observable<Boolean> {
        return this.apollo
            .mutate<{ updateUserTransactions: boolean }>({
                mutation: UPDATE_USER_TRANSACTIONS,
                variables: {
                    oldUser: oldUser,
                    newUser: newUser,
                    allTransactions: allTransactions,
                    startDate: startDate,
                    endDate: endDate
                },
                fetchPolicy: 'no-cache'
            })
            .pipe(map((result) => result.data.updateUserTransactions));
    }

    archiveUser(user: string, status: boolean): Observable<User> {
        return this.apollo
            .mutate<{ updateUserStatusById: User }>({
                mutation: ARCHIVE_USER,
                variables: {
                    id: user,
                    status: status,
                },
                fetchPolicy: 'no-cache'
            })
            .pipe(map((result) => result.data.updateUserStatusById));
    }

}
