import { FlowNavigationItem } from '@flow/components/navigation';

/**
 * Temp File manager nav
 */
export const fileManagerNav: FlowNavigationItem[] = [
    {
        type: 'group',
        children: [
            {
                id: 'fileManager',
                title: 'File Manager',
                subtitle: 'Manage your files in one place',
                type: 'basic',
                icon: 'folder_open',
                link: '/file-manager',
                roles: [],
            }
        ],
    },
    {
        type: 'group',
        children: [
            {
                id: 'products-header',
                title: 'Products',
                subtitle: '',
                type: 'collapsable',
                icon: 'category',
                children: [
                    {
                        id: 'products',
                        title: 'Products',
                        subtitle: 'Manage your products',
                        type: 'basic',
                        link: '/core/products/list',
                    },
                    {
                        id: 'categories',
                        title: 'Categories',
                        subtitle: 'Manage your categories',
                        type: 'basic',
                        link: '/core/products/categories',
                    },
                    {
                        id: 'uom',
                        title: 'Unit of Measures',
                        subtitle: 'Manage your uom',
                        type: 'basic',
                        link: '/core/products/uom',
                    }
                ],
            },

            {
                id: 'customers',
                title: 'Customers',
                subtitle: 'Manage your customers',
                type: 'basic',
                icon: 'store',
                link: '/core/customers/list',
                roles: [],
            },
            {
                id: 'factories',
                title: 'Factories',
                subtitle: 'Manage your factories',
                type: 'basic',
                icon: 'factory',
                link: '/core/factories/list',
                roles: [],
            },
        ],
    }
];

export const crmNav: FlowNavigationItem[] = [
    {
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                subtitle: 'Plan your day, track your sales',
                type: 'basic',
                icon: 'empty_dashboard',
                link: '/dashboard',
                roles: [],
            }
        ]
    },
    {
        type: 'group',
        children: [
            {
                id: 'quotes',
                title: 'Quotes',
                subtitle: 'Manage your quotes',
                type: 'basic',
                link: '/crm/quotes/list',
                icon: 'request_quote',
                roles: [],
            },

            {
                id: 'contacts',
                title: 'Contacts',
                subtitle: 'Manage your contacts',
                type: 'basic',
                icon: 'contacts',
                link: '/crm/contacts/list',
                roles: [],
            },
        ],
    },
    {
        type: 'group',
        children: [
            {
                id: 'products-header',
                title: 'Products',
                subtitle: '',
                type: 'collapsable',
                icon: 'category',
                children: [
                    {
                        id: 'products',
                        title: 'Products',
                        subtitle: 'Manage your products',
                        type: 'basic',
                        link: '/core/products/list',
                    },
                    {
                        id: 'categories',
                        title: 'Categories',
                        subtitle: 'Manage your categories',
                        type: 'basic',
                        link: '/core/products/categories',
                    },
                    {
                        id: 'uom',
                        title: 'Unit of Measures',
                        subtitle: 'Manage your uom',
                        type: 'basic',
                        link: '/core/products/uom',
                    }
                ],
            },

            {
                id: 'customers',
                title: 'Customers',
                subtitle: 'Manage your customers',
                type: 'basic',
                icon: 'store',
                link: '/core/customers/list',
                roles: [],
            },
            {
                id: 'factories',
                title: 'Factories',
                subtitle: 'Manage your factories',
                type: 'basic',
                icon: 'factory',
                link: '/core/factories/list',
                roles: [],
            },
        ],
    },
    {
        type: 'group',
        children: [
            {
                id: 'fileManager',
                title: 'File Manager',
                subtitle: 'Manage your files in one place',
                type: 'basic',
                icon: 'folder_open',
                link: '/file-manager',
                roles: [],
            },
            {
                id: 'flowbot-dashboard',
                title: 'flowBot Dashboard',
                subtitle: 'Manage your processed files',
                type: 'basic',
                icon: 'flowchart',
                link: '/flowbot/queue',
                roles: [],
            },
        ],
    },

];

/**
 * Temp Full Nav
 * */
export const fullNav: FlowNavigationItem[] = [
    {
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                subtitle: 'Plan your day, track your sales',
                type: 'basic',
                icon: 'empty_dashboard',
                link: '/dashboard',
                roles: [],
            },
        ],
    },
    {
        type: 'group',
        children: [
            {
                id: 'quotes',
                title: 'Quotes',
                subtitle: 'Manage your quotes',
                type: 'basic',
                link: '/crm/quotes/list',
                icon: 'request_quote',
                roles: [],
            },

            {
                id: 'contacts',
                title: 'Contacts',
                subtitle: 'Manage your contacts',
                type: 'basic',
                icon: 'contacts',
                link: '/crm/contacts/list',
                roles: [],
            },
        ],
    },
    {
        type: 'group',
        children: [
            {
                id: 'orders',
                title: 'Orders',
                subtitle: 'Manage your orders',
                type: 'basic',
                link: '/cm/orders/list',
                icon: 'orders',
                roles: [],
            },

            {
                id: 'invoices',
                title: 'Invoices',
                subtitle: 'Manage your invoices',
                type: 'basic',
                icon: 'receipt_long',
                link: '/cm/invoices/list',
                roles: [],
            },
            {
                id: 'commissions-header',
                title: 'Commissions',
                subtitle: 'Manage your commissions',
                type: 'collapsable',
                icon: 'wallet',
                children: [
                    {
                        id: 'commissions',
                        title: 'Commissions',
                        subtitle: 'Manage your commissions',
                        type: 'basic',
                        link: '/cm/commissions/list',
                    },
                    {
                        id: 'adjustments',
                        title: 'Adjustments',
                        subtitle: 'Manage your adjustments',
                        type: 'basic',
                        link: '/cm/adjust/list',
                    }
                ]
            },
        ],
    },
    {
        type: 'group',
        children: [
            {
                id: 'products-header',
                title: 'Products',
                subtitle: '',
                type: 'collapsable',
                icon: 'category',
                children: [
                    {
                        id: 'products',
                        title: 'Products',
                        subtitle: 'Manage your products',
                        type: 'basic',
                        link: '/core/products/list',
                    },
                    {
                        id: 'categories',
                        title: 'Categories',
                        subtitle: 'Manage your categories',
                        type: 'basic',
                        link: '/core/products/categories',
                    },
                    {
                        id: 'uom',
                        title: 'Unit of Measures',
                        subtitle: 'Manage your uom',
                        type: 'basic',
                        link: '/core/products/uom',
                    }
                ],
            },

            {
                id: 'customers',
                title: 'Customers',
                subtitle: 'Manage your customers',
                type: 'basic',
                icon: 'store',
                link: '/core/customers/list',
                roles: [],
            },
            {
                id: 'factories',
                title: 'Factories',
                subtitle: 'Manage your factories',
                type: 'basic',
                icon: 'factory',
                link: '/core/factories/list',
                roles: [],
            },
        ],
    },
    {
        type: 'group',
        children: [
            {
                id: 'fileManager',
                title: 'File Manager',
                subtitle: 'Manage your files in one place',
                type: 'basic',
                icon: 'folder_open',
                link: '/file-manager',
                roles: [],
            },
            {
                id: 'flowbot-dashboard',
                title: 'flowBot Dashboard',
                subtitle: 'Manage your processed files',
                type: 'basic',
                icon: 'flowchart',
                link: '/flowbot/queue',
                roles: [],
            },
        ],
    },
];
