import {Injectable} from '@angular/core';
import {SiteWideSearch} from '../types/search.type';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {siteWideSearch} from '../queries/search.query';
import {Apollo} from "apollo-angular";


@Injectable({
    providedIn: 'root'
})
export class SearchService {


    private _search: BehaviorSubject<SiteWideSearch>;

    constructor(
        private apollo: Apollo
    ) {
        this._search = new BehaviorSubject(null);
    }

    siteSearch(searchString: string): Observable<SiteWideSearch> {
        return this.apollo.query<{ siteWideSearch: SiteWideSearch }>({
            query: siteWideSearch,
            variables: {
                'searchString': searchString,
            }, fetchPolicy: 'no-cache'
        }).pipe(map((result: any) => result.data.siteWideSearch),
            tap((search) => {
                this._search.next(search);
            }));
    }


}
