<mat-dialog-content class="p-0 overflow-hidden">
    <div class="flex flex-col h-[65vh] min-h-[400px]">

        <!-- Header -->
        <div class="p-0 pt-1 border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-700 no-border-field">
            <mat-form-field class="w-full m-1" appearance="fill" >
                <mat-icon matPrefix class="text-gray-400 material-symbols-rounded">search</mat-icon>
                <input matInput [formControl]="searchControl" placeholder="Type a command or search..."
                class="flex-1 bg-transparent outline-none placeholder-gray-500 border-transparent"/>
                @if (searchControl.value) {
                    <button mat-icon-button matSuffix (click)="clearSearch()">
                    <mat-icon class="material-symbols-rounded ">close</mat-icon>
                    </button>
                }
                <button class="mx-1 " mat-icon-button matSuffix (click)="openFlowBotDialog()">
                    <img class="w-8 h-8" src="images/flowbot-static-icon.svg" alt="FlowBot">
                </button>
            </mat-form-field>
        </div>

        <!-- content -->
        <div class="flex-1 overflow-y-auto px-4 py-2 space-y-2 relative">

            <!-- history results -->
            @if (resultsAreHistory()) {

                @if (searchResults?.length) {
                    <div class="flex items-center pt-2">
                        <h3 class="text-lg font-bold">Recent Searches</h3>
                        <button mat-stroked-button class="ml-2 inline-flex gap-2 gradient-border rounded text-gray-400"
                        (click)="clearHistory()">
                            <mat-icon class="material-symbols-rounded dark:text-gray-200">delete</mat-icon>
                        </button>
                    </div>
                } @else { <!-- noHistory -->
                    <div class="flex flex-col items-center justify-center h-full text-center text-gray-500">
                        <div class="flex flex-col items-center mt-2 text-gray-500">
                            <mat-icon class="text-gray-500 material-symbols-rounded h-[70px] w-[70px] [font-size:70px]">block</mat-icon>
                            <p>No Recent Searches</p>
                            <span>Enter a search term or command above</span>
                        </div>
                    </div>
                }

                @for (result of searchResults; track result.id; let i = $index) {
                    <app-search-result-item [result]="result" [isHistory]="true"
                    (itemSelected)="resultSelected($event)"
                    (removeItem)="removeResult(result)"/>
                }

            } @else {

                @if (isSearching()) {
                    <div class="flex flex-col items-center justify-center h-full text-center text-gray-500">
                        <mat-spinner diameter="24" />
                        <span>Searching...</span>
                    </div>
                } @else {
                    <!-- Search Results -->
                    @for (entityType of getGroupedResults(); track entityType.key) {
                        @if (entityType.key !== 'flowbot') {
                            <h3 class="text-lg font-bold mb-2 mt-3">{{ entityType.key | titlecase }}</h3>
                        }
                        @for (result of entityType.value; track result.id; let i = $index) {
                            <app-search-result-item [result]="result" [isHistory]="false"
                            (itemSelected)="resultSelected($event)"
                            (removeItem)="removeResult(result)"/>
                        }
                    } @empty {
                        <!-- No Results -->
                        <p class="text-gray-500 mt-4 text-center">
                            No results found.
                        </p>
                    }
                }

            }

        </div>

        <!-- Footer -->
        <div class="p-0 pb-3 border-t border-gray-200 bg-white dark:bg-gray-700 dark:border-gray-500">
            <div class="w-full mx-auto p-4 pb-0 m-0">
                <div class="hidden sm:flex items-center justify-between">
                    <div class="flex items-center space-x-4">
                        <div class="flex items-center space-x-1">
                            <button mat-stroked-button class="inline-flex gap-2 gradient-border rounded"
                            flowHotkey="ArrowDown" [showModifiers]="false"
                            (click)="navigate('down')">
                                <mat-icon class="text-gray-400 dark:text-gray-200 material-symbols-rounded">keyboard_arrow_down</mat-icon>
                            </button>
                            <button mat-stroked-button class="inline-flex gap-2 gradient-border rounded"
                            flowHotkey="ArrowUp" [showModifiers]="false"
                            (click)="navigate('up')">
                                <mat-icon class="text-gray-400 dark:text-gray-200 material-symbols-rounded">keyboard_arrow_up</mat-icon>
                            </button>
                            <span>Navigate</span>
                        </div>

                        <div class="flex items-center space-x-1">
                            <button  class="inline-flex gap-2 gradient-border rounded text-gray-400 dark:text-gray-200"
                            flowHotkey="↵" [modifiers]="['ctrl']"
                            (click)="openNewTab()"></button>
                            <span>Open new tab</span>
                        </div>

                        <!--<div class="flex items-center space-x-1">
                            <button  class="inline-flex gap-2 gradient-border rounded text-gray-400 dark:text-gray-200"
                            flowHotkey="F" [modifiers]="['shift', 'ctrl']"
                            (click)="openFlowBotFullChat()"></button>
                        </div>-->

                        <div class="flex items-center space-x-1">
                            <button  class="inline-flex gap-2 gradient-border rounded text-gray-400 dark:text-gray-200"
                            flowHotkey="F" [modifiers]="['ctrl']"
                            (click)="openFlowBotDialog()"></button>
                            <span>Open</span>
                            <img class="w-6 h-6" src="images/flowbot-static-icon.svg" alt="FlowBot">
                        </div>
                    </div>

                    <div class="flex items-center space-x-1">
                        <button (click)="close()" mat-stroked-button class="inline-flex gap-2 gradient-border rounded text-gray-400 dark:text-gray-200">ESC</button>
                        <span>Close</span>
                    </div>
                </div>
            </div>
        </div>

    </div>

  </mat-dialog-content>








