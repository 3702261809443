import { GETSITEWIDESEARCH } from './../../queries/queries';
import { inject, Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { fullNav } from '@app/layout/layouts/main/temp-nav.types';
import { FlowNavigationItem } from '@flow/components/navigation';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, take } from 'rxjs';
import { GraphqlService } from '../graphql/graphql.service';
import { NotifierService } from '../notifier/notifier.service';
import { SiteWideSearch } from '@flow/types';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private _storageService = inject(StorageService);
  private _graphqlService = inject(GraphqlService);
  private _notifier = inject(NotifierService);
  private _router = inject(Router);
  private readonly STORAGE_KEY = 'recent_storage';
  private readonly MAX_HISTORY = 10; // Limit to the last 10 searches

  private readonly fullNav = fullNav;
  private navigationMap: Map<string, string> = new Map();

  constructor() { 
    // this.clearHistory();
    this.buildNavigationMap();
  }

  performSearch(query: string): Observable<SiteWideSearch[]> {
    console.log('Searching for:', query);
  
    const flowbot = this.addFlowBotResult(query);
  
    return this._graphqlService
      .executeQuery<{ getSiteWideSearch: SiteWideSearch[] }>(GETSITEWIDESEARCH,
        { title: query }
      )
      .pipe(
        take(1),
        map((response) => {
          const results = response?.getSiteWideSearch ?? [];
          // Normalize results before returning
          const mappedResults = this.normalizeSearchResults(results);
          console.log('Search results:', mappedResults);
          // Add the flowbot result to the beginning of the results
          return [flowbot, ...mappedResults];
        }),
        catchError((error) => {
          this._notifier.open(
            'error',
            'An error occurred while fetching search results. Please try again. If the problem persists please contact support.'
          );
          return of([flowbot]); // Still return the flowbot result even if the API fails
        })
      );
  }

  addFlowBotResult(query: string): SiteWideSearch {
      const flowBotResult: SiteWideSearch = {
        id: query,
        title: `<p><strong>Ask flowBot: </strong> ${query}<p>`,
        type: 'flowbot',
        link: '',
        linkId: query,
        customer: null,
        factory: null,
      };
      return flowBotResult;
  }

  // Retrieve search history from storage
  getSearchHistory(): SiteWideSearch[] {
    return this._storageService.get<SiteWideSearch[]>(this.STORAGE_KEY) || [];
  }

  // Save a new search term
  addSearch(term: SiteWideSearch): void {
    let history = this.getSearchHistory();

    // Remove duplicates
    history = history.filter(item => item.id !== term.id);

    // Add the new search at the beginning
    history.unshift(term);

    // Keep only the last `MAX_HISTORY` searches
    if (history.length > this.MAX_HISTORY) {
      history = history.slice(0, this.MAX_HISTORY);
    }

    // Store the updated search history
    this._storageService.set(this.STORAGE_KEY, history);
  }

  removeSearch(term: SiteWideSearch): void {
    let history = this.getSearchHistory();
    // Remove the specified search term
    history = history.filter(item => item.id !== term.id);
    // Store the updated search history
    this._storageService.set(this.STORAGE_KEY, history);
  }
  
  // Clear the search history
  clearHistory(): void {
    this._storageService.remove(this.STORAGE_KEY);
  }

  getIconForType(type: string): string {
    return this.navigationMap.get(type) || 'help_outline';
  }

  routeToSelected(result: SiteWideSearch, openInNewTab: boolean = false): void {
  
    const targetRoute = this.buildRoute(result);
    const queryParams = this.buildQueryParams(result);

    if (openInNewTab) {
      const urlTree = this._router.createUrlTree(targetRoute, { queryParams });
      const absoluteUrl = this._router.serializeUrl(urlTree);
      window.open(absoluteUrl, '_blank');
      return;
    }

    const currentUrl = this._router.url;
    const isSameSection = currentUrl.startsWith('/' + result.link);

    if (isSameSection) {
      this._router.navigateByUrl('/blank', { skipLocationChange: true }).then(() => {
        this._router.navigate(targetRoute, { queryParams });
      });
    } else {
      this._router.navigate(targetRoute, { queryParams });
    }
  }

  /**
   * Removes HTML tags from a string and returns plain text.
   *  Also removes the static prefix "Ask flowBot:" from the result.
   * @param html The string containing HTML content.
   * @returns The plain text with HTML tags removed.
   */
  cleanHtml(html: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    const text = tempElement.textContent || tempElement.innerText || '';
    // Remove the "Ask flowBot:" prefix if it exists
    return text.replace(/^Ask flowBot:\s*/i, '').trim();
  }

  /**
   * Normalize legacy result types for compatibility with the current app.
   * This updates outdated result types or links to their new equivalents.
   */
  private normalizeSearchResults(results: SiteWideSearch[]): SiteWideSearch[] {
    return results.map((item) => {
      const type = item.type;
      switch (type) {
        case 'Check':
          item.type = 'Commission';
          item.link = 'commissions';
          return item;
        case 'Expense':
          item.type = 'Adjustment';
          item.link = 'adjustments';
          return item;
      }
      return item;
    });
  }

  private buildNavigationMap(): void {
    const items = this.getFlatBasicItems(this.fullNav);
    // Names and icons of the sidebar menu to map in the query response and display in the view with the corresponding icons.
    this.navigationMap = new Map(items.map(item => [item.id!, item.icon!]));
    // console.log('Navigation Map:', this.navigationMap);
  }
  
  private buildRoute(result: SiteWideSearch): any[] {
    const items = this.getFlatBasicItems(this.fullNav);
    // find the item that matches the result link
    const item = items.find(item => item.id === result.link);
    const link = item?.link || result.link;
    return [link, result.linkId];

  }
  
  private buildQueryParams(result: SiteWideSearch): Record<string, string | number> | undefined {
    const type = result.type?.toLowerCase();
    switch (type) {
      case 'invoice':
        return { invoiceId: result.id };
      case 'credit':
        return { creditId: result.linkId };
      default:
        return undefined;
    }
  }

  private getFlatBasicItems(nav: FlowNavigationItem[]): FlowNavigationItem[] {
    const flatItems: FlowNavigationItem[] = [];
  
    const recurse = (
      items: FlowNavigationItem[],
      parentIcon?: string
    ): void => {
      for (const item of items) {
        const iconToUse = item.icon || parentIcon;
  
        if (item.link) {
          flatItems.push({
            ...item,
            icon: item.icon || parentIcon, 
          });
        }
        // If the item has children, recurse into them
        if (item.children && item.children.length > 0) {
          recurse(item.children, iconToUse);
        }
      }
    };
  
    recurse(nav);
  
    return flatItems;
  }


}
