<!-- Loading bar -->
<flow-loading-bar></flow-loading-bar>
<!--<app-notifier></app-notifier>-->
<!-- Wrapper -->
<flow-vertical-navigation
    #nav
    class=" print:hidden border-r-0"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [className]="'main-nav'"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container flowVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center py-8 px-2">
            <a routerLink="./">
                <img class="min-w-24 max-w-24" [src]="logo" alt="Logo image">
            </a>
        </div>
    </ng-container>
</flow-vertical-navigation>
<div class="flex flex-col flex-auto w-full min-w-0 bg-gradient-to-b dark:from-[#1E293B] dark:to-[#435267] from-[#F6F7F9] to-[#E9EDF4]">
    <mat-drawer-container class="flex-auto h-full w-full bg-transparent">
        <mat-drawer #flowBot
                    [opened]="drawerOpened"
                    position="end"
                    class="max-h-screen bg-gradient-to-b dark:from-[#1E293B] dark:to-[#435267] from-[#E9EDF4] to-[#F6F7F9] rounded-l-2xl w-full md:w-1/2"
                    disableClose
                    [autoFocus]="false">
            @if (drawerOpened) {
                <div class="relative flex flex-col w-full h-full">
                    <flowbot-toolbar class="sticky top-0 z-10" (fullChatMode)="navigateToFullChat()"/>
                    <flowbot-chat-dialog [searchInput]="searchInput()"/>
                </div>
            }
        </mat-drawer>

        <mat-drawer-content class="flex flex-col overflow-hidden">

            <!-- Header -->
            <div class="relative flex flex-0 items-center w-full h-20 px-4 md:px-6 z-49 print:hidden bg-transparent">
                <!-- Logo and buttons -->
                <div class="flex items-center pr-2 space-x-2">
                    <!--<a routerLink="./">
                        <img class="w-24" [src]="logo" alt="Logo image">
                    </a>-->
                    <button
                        class="flex md:hidden"
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                    </button>
                </div>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                    <flowbot-button (onClick)="openSearchDialog()" *ngIf="!isTargetRoute" />
                    <app-admin-header-button/>
                    <flow-fullscreen class="hidden md:block"></flow-fullscreen>
                    <most-used />
                    <message-center />
                    <menu-user />
                </div>
            </div>

            <!-- Main Content -->
            <div
                class="flex flex-col flex-auto overflow-hidden bg-gradient-to-b dark:from-[#1E293B] dark:to-[#435267] from-[#E9EDF4] to-[#F6F7F9] rounded-tl-[32px]">

                @if (true) {
                    <router-outlet></router-outlet>
                }
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
