<div *ngFor="let notification of notifications">
    <div data-component="notifier" class="fixed pl-6 pr-12 opacity-0 py-4 max-w-[450px] border-l-8 border-solid bg-white text-black rounded-[20px] z-[9999999999]"
        [ngStyle]="{'top': notification.notifierTopDistance, 'bottom': notification.notifierBottomDistance}"
        [ngClass]="{'border-emerald-700': notification.type === 'success', 'border-red-800': notification.type === 'error', 'border-primary': notification.type === 'info', 'opacity-100': notification.isVisible,
        'right-transition -right-30 ': (notification.notificationSideLocation === 'top-right' || notification.notificationSideLocation === 'bottom-right') && notification?.updateAnimationPosition,
        'left-transition -left-5': (notification.notificationSideLocation === 'top-left' || notification.notificationSideLocation === 'bottom-left') && notification?.updateAnimationPosition,
        'right-5': notification.isVisible && (notification.notificationSideLocation === 'top-right' || notification.notificationSideLocation === 'bottom-right'),
        'left-22': notification.isVisible && (notification.notificationSideLocation === 'top-left' || notification.notificationSideLocation === 'bottom-left')
    }">
        <div class="font-bold text-2xl">{{ notification.title }}</div>
        <div class="text-lg leading-5 text-neutral-500 mt-2 mb-5" *ngIf="notification.message">{{ notification.message }}</div>
        <div class="text-[14px] text-neutral-500">{{ notification.timestamp }}</div>
        <div class="absolute top-5 right-5">
            <i *ngIf="notification.type === 'success'" class="text-emerald-700 pi pi-thumbs-up text-xl"></i>
            <i *ngIf="notification.type === 'error'" class="text-red-800 pi pi-thumbs-down text-xl"></i>
            <i *ngIf="notification.type === 'info'" class="text-primary pi pi-info-circle text-xl"></i>
        </div>
        <!-- <i (click)="clearNotifier(notification)" class="cursor-pointer absolute -top-1 -right-0.5 pi pi-times text-gray-500"></i> -->
    </div>
</div>
