import gql from 'graphql-tag';
import {customerFragment} from "../customers/customers.query";
import {factoriesFragment} from "../factories/factory.query";

export const usersFragment = gql`
    fragment UsersFields on User{
        id
        isInside
        isOutside
        firstName
        lastName
        email
        username
        status
        role
    }
`;

export const privilegeFragment = gql`
    fragment PrivilegeFields on PrivilegeRefined {
        privilegeId
        privilege
        option
    }
`;

export const rolesFragment = gql`
    fragment RolesFields on RolesPrivilegeRefined {
        role
        permissionType
        privileges {
            ...PrivilegeFields
        }
    }
    ${privilegeFragment}
`;

export const routesPermissionsFragment = gql`
    fragment RoutesPermissionsFields on ResourceRefined {
        resource
        roles {
            ...RolesFields
        }
    }
    ${rolesFragment}
`;

export const salesRepSelectionFragment = gql`
    fragment SalesRepSelectionFields on SalesRepSelection{
        id
        customer {
            ...CustomerFields
        }
        factory {
            ...FactoriesFields
        }
        createDate
        createdBy
        customerSelectionType
        outsideRepSplit {
            id
            user {
                ...UsersFields
            }
            splitRate
        }
    }
    ${customerFragment}
    ${factoriesFragment}
    ${usersFragment}
`;

export const findUserByRole = gql`
    query ($roleName: String){
        findUserByRole(roleName: $roleName) {
            ...UsersFields
        }
    }
    ${usersFragment}
`;

export const findUserById = gql`
    query ($id: String!){
        findUserById(id: $id){
            ...UsersFields
        }
    }
    ${usersFragment}
`;

export const findUserByTenant = gql`
    query{
        findUserByTenant{
            ...UsersFields
        }
    }
    ${usersFragment}
`;

export const updateUser = gql`
    mutation ($input: UserInput!, $keycloakPersistence: Boolean){
        updateUser(input: $input, keycloakPersistence: $keycloakPersistence){
            ...UsersFields
        }
    }
    ${usersFragment}
`;

export const updateUserPassword = gql`
    mutation ($userId: String!, $password: String!) {
        updateUserPassword(userId: $userId, password: $password)
    }
`;

export const getWPAutologinLink = gql`
    query {
        getWPAutologinLink
    }
`;

export const GET_DEFAULT_SELECTION = gql`
    query getDefaultSelection($customerId: Int, $factoryId: Int) {
        getDefaultSelection(customerId: $customerId, factoryId: $factoryId) {
            ...SalesRepSelectionFields
        }
    }
    ${salesRepSelectionFragment}
`;

export const FIND_ALL_DEFAULT_SELECTIONS = gql`
    query {
        findAllDefaultSelections {
            ...SalesRepSelectionFields
        }
    }
    ${salesRepSelectionFragment}
`;

export const SAVE_SALES_REP_SELECTION = gql`
    mutation saveSalesRepSelection($input: SalesRepSelectionInput!) {
        saveSalesRepSelection(input: $input) {
            ...SalesRepSelectionFields
        }
    }
    ${salesRepSelectionFragment}
`;

export const UPDATE_SALES_REP_SELECTION = gql`
    mutation updateSalesRepSelection($input: SalesRepSelectionInput!) {
        updateSalesRepSelection(input: $input) {
            ...SalesRepSelectionFields
        }
    }
    ${salesRepSelectionFragment}
`;

export const DELETE_SALES_REP_SELECTION = gql`
    mutation DeleteSalesRepSelection($id: String!) {
        deleteSalesRepSelection(id: $id)
    }
`;

export const findAllRoles = gql`
    query {
        findAllRoles
    }
`;

export const findPrivileges = gql`
{
    findPrivileges {
        privilegeId
        privilege
    }
}
`;

export const findResources = gql`
    query {
        findResources {
            ...RoutesPermissionsFields
        }
    }
    ${routesPermissionsFragment}
`;


export const findPrivilegesByResource = gql`
    query findPrivilegesByResource ($resource: String!) {
        findPrivilegesByResource (resource: $resource) {
            ...RoutesPermissionsFields
        }
    }
    ${routesPermissionsFragment}
`;

export const saveRoleResourceGrid = gql`
    mutation saveRoleResourceGrid($roleResources: [ResourceInput]) {
        saveRoleResourceGrid(roleResources: $roleResources) {
            ...RoutesPermissionsFields
        }
    }
    ${routesPermissionsFragment}
`;

export const ADD_TEAM_MEMBER = gql`
    mutation AddTeamMember($input: UserInput!, $keycloakPersistence: Boolean) {
        addTeamMember(input: $input, keycloakPersistence: $keycloakPersistence)
    }
`;

export const ARCHIVE_USER = gql`
    mutation UpdateUserStatusById($id: String, $status: Boolean){
        updateUserStatusById(id: $id, status: $status){
            ...UsersFields
        }
    }
    ${usersFragment}
`;

export const UPDATE_USER_TRANSACTIONS =  gql`
    mutation UpdateUserTransactions($oldUser: String!, $newUser: String!, $allTransactions: Boolean!, $startDate: String, $endDate: String){
        updateUserTransactions(oldUser: $oldUser, newUser: $newUser, allTransactions: $allTransactions, startDate: $startDate, endDate: $endDate)
    }
`;

export const REMOVE_SESSION = gql`
    mutation RemoveUserSession{
        removeUserSession
    }
`;
