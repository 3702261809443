<div #container tabindex="0" 
    class="flex items-center mt-2 p-3 border rounded-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-800 transition cursor-pointer
         focus:outline-none focus:ring-1 
         focus:ring-gray-300 dark:focus:ring-gray-600 
         focus:bg-gray-100 dark:focus:bg-gray-700 relative"
    flowHotkey="Enter" [showModifiers]="false"
    (click)="select()"
    (mouseenter)="focus()">

    @if (isHistory()) {
      <button mat-icon-button class="mr-4" (click)="remove()">
        <mat-icon class="text-gray-500 dark:text-gray-200 text-2xl material-symbols-rounded border rounded-md">
          close
        </mat-icon>
      </button>
    }

    @if (result().type == 'flowbot') {
      <img class="w-8 h-8 mr-4 " src="images/flowbot-static-icon.svg" alt="FlowBot">
    } @else {
      <mat-icon class="text-gray-500 text-4xl mr-4 mt-1 material-symbols-rounded border rounded-md w-10 h-10">
        {{ getIconForType(result().link) }}
      </mat-icon>
    }

  
    <div class="flex flex-col">
      <div class="text-base font-semibold text-gray-800 dark:text-gray-400">
        <span [innerHTML]="result().title"></span>
      </div>
  
      <div class="text-sm text-gray-600 flex gap-4 mt-1 dark:text-gray-300">
        @if (isHistory() && result().type != 'flowbot'){
          <span class="flex items-center gap-1">
            {{ result().type }}
          </span>
        }
        @if (result().factory) {
          <span class="flex items-center gap-1">
            <mat-icon class="text-gray-500 dark:text-gray-300 text-sm material-symbols-rounded">factory</mat-icon>
            {{ result().factory }}
          </span>
        }
        @if (result().customer) {
          <span class="flex items-center gap-1">
            <mat-icon class="text-gray-500 dark:text-gray-300 text-sm material-symbols-rounded">business</mat-icon>
            {{ result().customer }}
          </span>
        }
      </div>
    </div>

    @if (hasFocus()) {
        <mat-icon class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-200 text-base material-symbols-rounded">
            <span class="text-3xl material-symbols-outlined">
                keyboard_return
                </span>
        </mat-icon>
      }
  </div>
