import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalendarModule} from "primeng/calendar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-update-app-dialog',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, CalendarModule, MatFormFieldModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatDialogModule],
    templateUrl: './update-app-dialog.component.html',
    styles: [
        `
            .update-app-dialog {
                overflow: visible;
                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {
                    overflow: visible;
                    .mat-mdc-dialog-surface {
                        overflow: visible;
                        padding: 0 !important;
                    }
                }
            }
        `,
    ],
})
export class UpdateAppDialogComponent implements OnInit{
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<UpdateAppDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        ) {
    }

    ngOnInit() {
    }
}
