import gql from "graphql-tag";


export const unitOfMeasureFragment  = gql`
    fragment UnitOfMeasureFields on UnitOfMeasures{
        uomId
        title
        multiply
        multiplyBy
        description
    }
`;

export const findAllUOM = gql`
    query {
        findAllUOM{
            ...UnitOfMeasureFields
        }
    }
    ${unitOfMeasureFragment}
`;

export const saveUOMs = gql`
    mutation($input: UOMInput ){
        saveUOMs(input: $input) {
            ...UnitOfMeasureFields
        }
    }
    ${unitOfMeasureFragment}
`;
