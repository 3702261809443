import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, catchError, map, Observable, of, switchMap, take, tap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {environment} from '../../../environments/environment';
import {REMOVE_SESSION} from "../../modules/admin/users/users.query";
import {Apollo} from "apollo-angular";
import { ActivatedRoute, Router } from '@angular/router';
import { FlowNavigationItem } from '@flow/components/navigation';
import { CurrentUserPermission, MinifiedPermissions, UserPermissions } from 'app/modules/admin/users/user.type';
import { findUserPermissions } from 'app/app.query';

@Injectable({providedIn: 'root'})
export class AuthService {

    private _authenticated: boolean = false;
    private loginUri: any;
    private apiPublic: any;
    private appUrl: any;

    private _routesPermissions = new BehaviorSubject<any>(null);
    public routesPermissions$ = this._routesPermissions.asObservable();
    public defaultNavigation: FlowNavigationItem[] = [];
    public allowCommissionsVisibility = false;

    // Method to update routesPermissions
    setRoutesPermissions(value: any) {
        this._routesPermissions.next(value);
    }

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
            private _apollo: Apollo,
            private _activatedRoute: ActivatedRoute,
            private _router: Router,
        ) {
        this.loginUri = environment.apiLogin;
        this.apiPublic = environment.apiPublic;
        this.appUrl = environment.appUrl;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get accessToken(): string {
        return localStorage.getItem('access_token');
    }

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('access_token', token);
    }

    get currentUserId(): string {
        return localStorage.getItem('current_user');
    }

    set currentUserId(currentUserId: string) {
        localStorage.setItem('current_user', currentUserId);
    }

    get userFirstName(): string {
        return localStorage.getItem('current_user_firstname');
    }

    set userFirstName(userFirstName: string) {
        localStorage.setItem('current_user_firstname', userFirstName);
    }

    get userLastName(): string {
        return localStorage.getItem('current_user_lastname');
    }

    set userLastName(userLastName: string) {
        localStorage.setItem('current_user_lastname', userLastName);
    }

    get userEmail(): string {
        return localStorage.getItem('current_user_email');
    }

    set userEmail(userEmail: string) {
        localStorage.setItem('current_user_email', userEmail);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get tenant(): string {
        return localStorage.getItem('current_tenant');
    }

    set tenant(tenant: string) {
        localStorage.setItem('current_tenant', tenant);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        const body = {
            email: email,
        };
        return this._httpClient.post(this.apiPublic + '/reset-password', body, { headers: headers });
    }

    /**
     * Sign in
     *
     * @param credentials
     * @param code
     */
    signIn(credentials?: { email: string; password: string, rememberMe: boolean }, code?): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }
        const httpOptions = {
            headers: new HttpHeaders({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };
        const body = new URLSearchParams();
        body.set('client_id', 'app');
        if (credentials) {
            body.set('username', credentials.email);
            body.set('password', credentials.password);
            body.set('grant_type', 'password');
            body.set('client_secret', environment.clientSecret);
            body.set('remember_me', credentials.rememberMe ? credentials.rememberMe.toString() : 'false');
        } else {
            body.set('grant_type', 'authorization_code');
            body.set('code', code);
            body.set('redirect_uri', this.appUrl + '/reset-password');
        }
        return this._httpClient
            .post(this.loginUri + '/realms/' + environment.realm + '/protocol/openid-connect/token', body.toString(), httpOptions)
            .pipe(
                switchMap((response: any) => {
                    this.accessToken = response.access_token;
                    this.refreshToken = response.refresh_token;
                    const meta = AuthUtils.getUserMeta(response.access_token);
                    this.userLastName = meta.lastName;
                    this.userFirstName = meta.firstName;
                    this.userEmail = meta.email;
                    this.currentUserId = meta.id;
                    this.tenant = meta.tenant;
                    // Set the authenticated flag to true
                    this._authenticated = true;
                    //this.locationStrategy.getBaseHref();

                    // Return a new observable with the response
                    return of(response);
        }));
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        if (this.accessToken === undefined) {
            console.log('error');
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };

        const body = new URLSearchParams();
        body.set('client_id', 'app');
        body.set('grant_type', 'refresh_token');
        body.set('refresh_token', this.refreshToken);

        // Sign in using the token
        return this._httpClient.post(this.loginUri + '/realms/' + environment.realm + '/protocol/openid-connect/token', body, httpOptions).pipe(catchError(() => // Return false
            of(false)), switchMap((response: any) => {
            // Store the access token in the local storage
            this.accessToken = response.access_token;
            this.refreshToken = response.refresh_token;

            // Set the authenticated flag to true
            this._authenticated = true;

            // Return true
            return of(true);
        }));
    }

    hasRoles(accessRoles): boolean {
        const claims = AuthUtils.getUserMeta(this.accessToken);
        const actualRoles: string[] = accessRoles;
        if (actualRoles?.length > 0 && !!claims.roles) {
            return actualRoles.some(r => claims.roles.includes(r));
        } else {
            return false;
        }
    }


    /**
     * Sign out
     */
    signOut(): Observable<any> {
        return this._apollo
            .mutate<{ removeUserSession: boolean }>({
                mutation: REMOVE_SESSION,
                fetchPolicy: 'no-cache'
            }).pipe(
                switchMap((response: any) => {
                    // Store the access token in the local storage
                    localStorage.clear();
                    // Set the authenticated flag to false
                    this._authenticated = false;

                    // Return true
                    return of(false);
                }),
                catchError((err) => {
                    localStorage.clear();
                    // Set the authenticated flag to false
                    this._authenticated = false;
                    return of(false);
                })
            );

    }


    isLoggedIn(): boolean {
        return !!this.accessToken;
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in and the access token is not expired
        if (this._authenticated && !AuthUtils.isTokenExpired(this.accessToken)) {
            return of(true);
        }

        // If the user is logged in but the access token is expired, sign in using refresh token
        if (this._authenticated && AuthUtils.isTokenExpired(this.accessToken)) {
            return this.signInUsingToken();
        }
        // Check the access token expire date
        if (this.refreshToken && AuthUtils.isTokenExpired(this.accessToken)) {
            // If the access token is expired, sign in using refresh token
            return this.signInUsingToken();
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            // If the access token is expired, sign in using refresh token
            return this.signInUsingToken();
        }

        // If the access token exists and is not expired, return true
        return of(true);
    }


    getAuthorizedRoles(resource: string, privilege: string): string[] {
        let authorizedRoles: string[] = [];
        this.routesPermissions$.pipe(
            take(1),
        ).subscribe(value => {
            if (value && value[resource] && value[resource][privilege]) {
                authorizedRoles = value[resource][privilege];
            }
        });
        return authorizedRoles;
    }

    getIsAuthorized(resource: string, privilege: string): Promise<boolean> {
        let access;
        this.routesPermissions$.pipe(
            tap(permissions => {
                if (permissions && permissions[resource] && permissions[resource][privilege]) {
                    access = true;
                } else {
                    access = false;
                }
            })
        ).subscribe();

        return access;
    }

    getUserRole(): string {
        const userData = AuthUtils.getUserMeta(this.accessToken);
        const stringsToRemove = ["offline_access", "uma_authorization", "default-roles-app"];
        return userData.roles.filter(role => !stringsToRemove.includes(role))[0];
    }

    findUserPermissions(): Observable<UserPermissions> {
        return this._apollo
            .query<{ findUserPermissions: UserPermissions }>({
                query: findUserPermissions,
                fetchPolicy: 'no-cache'
            }).pipe(
                map((result: any) => {
                    const data = result.data.findUserPermissions;

                    // Transform returned data in string, make it unreadable for normal users, and store it in local storage
                    const jsonString = JSON.stringify(data);
                    const encodedString = btoa(jsonString);
                    localStorage.setItem('current_user_permissions', encodedString);

                    this.setUserPermissions(data);

                    return data
                }),
                catchError(error => throwError(error))
            );
    }

    setUserPermissions(permissions: UserPermissions, reloadedPage = false): void {
        const minimizedPermissions = this.minimizeRoutesPermissions(permissions.permissions);
        this.setRoutesPermissions(minimizedPermissions);
        this.defaultNavigation = permissions.navigationData;
        this.allowCommissionsVisibility = permissions.roleOptions?.allowCommissionsVisibility;
        console.log('minimizedPermissions', minimizedPermissions)
        console.log('allowCommissionsVisibility', this.allowCommissionsVisibility);

        // If the user reloads the page, he should stay where he reloaded
        if (!reloadedPage) {
            const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
            console.log('redirectURL', redirectURL);
            this._router.navigateByUrl(redirectURL);
        }
    }

    minimizeRoutesPermissions(response: CurrentUserPermission[]): MinifiedPermissions {
        const userRole = this.getUserRole();

        const resourcePrivilegeRoleMap = {};

        response.forEach(resourceData => {
            const resource = resourceData.resource;
            const privileges = resourceData.privileges;

            // Map resource to its privileges
            resourcePrivilegeRoleMap[resource] = {};
            ['view', 'edit', 'delete'].forEach(defaultPrivilege => {
                let roleArray = ['administrator', 'owner', 'super-admin'];
                if (userRole !== 'administrator' && userRole !== 'owner' && userRole !== 'super-admin') {
                    roleArray.push(userRole);
                }

                if (privileges.includes(defaultPrivilege)) {
                    resourcePrivilegeRoleMap[resource][defaultPrivilege] = roleArray;
                } else {
                    resourcePrivilegeRoleMap[resource][defaultPrivilege] = ['administrator', 'owner', 'super-admin'];
                }
            });
        });

        return resourcePrivilegeRoleMap;
    }
}
