import { Routes } from '@angular/router';
import { AuthGuard } from '@flow/guards/auth.guard';
import { NoAuthGuard } from '@flow/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';

export const ROUTES: Routes = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'tenant-picker',
                loadChildren: () =>
                    import(
                        './routes/auth/tenant-picker/tenant-picker.routes'
                    ).then((m) => m.default),
            },
            {
                path: 'auth-callback',
                loadChildren: () =>
                    import(
                        './routes/auth/auth-callback/auth-callback.routes'
                    ).then((m) => m.default),
            },
            {
                path: 'unauthorized',
                loadChildren: () =>
                    import(
                        './routes/auth/unauthorized/unauthorized.routes'
                    ).then((m) => m.default),
            },
            {
                path: 'reset-tenant',
                loadChildren: () =>
                    import(
                        './routes/auth/reset-tenant/reset-tenant.routes'
                    ).then((m) => m.default),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },

        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('./routes/auth/sign-out/sign-out.routes').then(
                        (m) => m.default
                    ),
            },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('@app/routes/signup/signup.routes').then(
                        (m) => m.SIGN_UP_ROUTES
                    ),
            },
        ],
    },

    //Onboarding Routes
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'onboarding',
                loadChildren: () =>
                    import('@app/routes/onboarding/onboarding.routes').then(
                        (m) => m.OnboardingRoutes
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('./routes/admin/dashboard/dashboard.routes').then(
                        (m) => m.DashboardRoutes
                    ),
            },
            {
                path: 'file-manager',
                loadChildren: () =>
                    import(
                        './routes/admin/file-manager/file-manager.routes'
                    ).then((m) => m.FileManagerRoutes),
            },
            {
                path: 'core',
                loadChildren: () =>
                    import('./routes/admin/core/core.routes').then(
                        (c) => c.CoreRoutes
                    ),
            },
            {
                path: 'cm',
                loadChildren: () =>
                    import(
                        './routes/admin/commissions-management/commissions-management.routes'
                    ).then((c) => c.CommissionsManagementRoutes),
            },
            {
                path: 'crm/quotes',
                loadChildren: () =>
                    import('@app/routes/admin/crm/routes/quotes/quotes.routes').then(
                        (c) => c.quotesRoutes
                    ),
            },
            {
                path: 'crm/contacts',
                loadChildren: () =>
                    import('@app/routes/admin/crm/routes/contacts/contacts.routes').then(
                        (c) => c.contactsRoutes
                    ),
            },
            {
                path: 'flowbot',
                loadChildren: () =>
                    import('./routes/admin/flowbot/flowbot.routes').then(
                        (m) => m.FlowbotRoutes
                    ),
            },
            {
                path: 'administrator',
                loadChildren: () =>
                    import('./routes/admin/admin-settings/admin-settings.routes').then(
                        (m) => m.default
                    ),
            },
            {
                path: 'no-internet',
                loadChildren: () =>
                    import('./routes/no-internet/no-internet.routes').then(
                        (m) => m.noInternetRoutes
                    ),
            },
            {path: 'export-pdf', loadChildren: () => import('../@flow/@legacy/pdf-export/pdf-export.routes').then(m => m.pdfExportRoutes)},
            // Wrong route
            {
                path: '**',
                loadChildren: () =>
                    import('./routes/error-404/error-404.routes').then(
                        (m) => m.error404Routes
                    ),
            },
        ],
    },
];
