import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
    EnvironmentProviders,
    Provider,
    importProvidersFrom,
    inject,
    provideEnvironmentInitializer,
} from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { FlowConfig } from './services/config';
import { FLOW_CONFIG } from './services/config/config.constants';
import {
    FlowConfirmationService,
    flowLoadingInterceptor,
    FlowLoadingService,
    FlowSplashScreenService,
} from './services';
import { FlowMediaWatcherService } from './services/media-watcher/media-watcher.service';
import { FlowPlatformService } from './services/platform/platform.service';
import { FlowUtilsService } from './services/utils/utils.service';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from "extract-files/extractFiles.mjs";
import isExtractableFile from "extract-files/isExtractableFile.mjs";
import {ApolloClientOptions, InMemoryCache, split} from "@apollo/client/core";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '../environments/environment';
import { WebSocketAuthService } from '@flow/services/auth/websocket.auth.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';

import { MyPreset } from '@flow/primeNg/theme';

export type FlowProviderConfig = {

    flow?: FlowConfig;
};

/**
 * Flow provider
 */
export const provideFlow = (
    config: FlowProviderConfig
): Array<Provider | EnvironmentProviders> => {
    // Base providers
    const providers: Array<Provider | EnvironmentProviders> = [
        {
            // Disable 'theme' sanity check
            provide: MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme: false,
                version: true,
            },
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
            },
        },
        {
            provide: FLOW_CONFIG,
            useValue: config?.flow ?? {},
        },
        provideAnimationsAsync(),
        providePrimeNG({
            theme: {
                preset: MyPreset,
                options: {
                    cssLayer: {
                        name: 'primeng',
                        order: 'app-styles, tailwind-base, primeng, tailwind-utilities'
                    }
                }
            }
        }),
        importProvidersFrom(MatDialogModule),
        provideEnvironmentInitializer(() => inject(FlowConfirmationService)),
        provideHttpClient(withInterceptors([flowLoadingInterceptor])),
        provideEnvironmentInitializer(() => inject(FlowLoadingService)),
        provideEnvironmentInitializer(() => inject(FlowMediaWatcherService)),
        provideEnvironmentInitializer(() => inject(FlowPlatformService)),
        provideEnvironmentInitializer(() => inject(FlowSplashScreenService)),
        provideEnvironmentInitializer(() => inject(FlowUtilsService)),
        provideHttpClient(withInterceptors([flowLoadingInterceptor])),
        provideHttpClient(),
        {
            provide: APOLLO_OPTIONS,
            useFactory: (
                httpLink: HttpLink,
                webSocketAuthService: WebSocketAuthService
            ): ApolloClientOptions<unknown> => {
                const http = httpLink.create({
                    uri: environment.api,
                    extractFiles: (body) => extractFiles(body, isExtractableFile),
                });

                const wsLink = new GraphQLWsLink(
                    createClient({
                        url: environment.api.replace('http', 'ws'), // Ensure this points to the WebSocket API URL
                        lazy: true, // Only connect when needed
                        connectionParams: async () => {
                            return await webSocketAuthService.getConnectionParams();
                        },
                    })
                );

                const link = split(
                    ({ query }) => {
                        const definition = getMainDefinition(query);
                        return (
                            definition.kind === 'OperationDefinition' &&
                            definition.operation === 'subscription'
                        );
                    },
                    wsLink, // WebSocket for subscriptions
                    http    // HTTP for queries and mutations
                );

                return {
                    link,
                    cache: new InMemoryCache({
                        addTypename: true,
                        resultCaching: false,
                    }),
                };
            },

            deps: [HttpLink, WebSocketAuthService],

        },
        Apollo
    ];


    // Return the providers
    return providers;
};
