import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FlowFullscreenComponent } from '@flow/components/fullscreen';
import { FlowLoadingBarComponent } from '@flow/components/loading-bar';
import { FlowNavigationItem, FlowNavigationService, FlowVerticalNavigationComponent } from '@flow/components/navigation';
import { FlowMediaWatcherService } from '@flow/services/media-watcher';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { Subject, takeUntil } from 'rxjs';
import {UserComponent} from "../../common/user/user.component";
import { FLOWRMS_VERSION } from '@flow/version';

@Component({
    selector     : 'main-layout',
    templateUrl  : './main-layout.component.html',
    styleUrls    : ['./main-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports: [FlowLoadingBarComponent, FlowVerticalNavigationComponent, MatButtonModule, MatIconModule, FlowFullscreenComponent, SearchComponent, NgIf, RouterOutlet, UserComponent, RouterLink],
})
export class MainLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: FlowNavigationItem[];
    navigationAppearance: 'default' | 'dense' = 'dense';
    version = FLOWRMS_VERSION;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _flowMediaWatcherService: FlowMediaWatcherService,
        private _flowNavigationService: FlowNavigationService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        const storedPermissions = localStorage.getItem('current_user_permissions');
        const decodedPermissions = atob(storedPermissions);
        const permissionsJson = JSON.parse(decodedPermissions);
        this.navigation = permissionsJson?.navigationData;

        // Subscribe to media changes
        this._flowMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');

                // Change the navigation appearance
                this.navigationAppearance = this.isScreenSmall ? 'default' : 'dense';
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._flowNavigationService.getComponent<FlowVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void
    {
        this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');
    }
}
