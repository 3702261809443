import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {NotifierComponent} from './shared/components/notifications/notifier/notifier.component';
import {DatePipe} from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { Subject, take, takeUntil } from 'rxjs';
import { UpdateAppDialogComponent } from './shared/components/update-app-dialog/update-app-dialog.component';
import { AppService } from './app.service';
import { AuthService } from './core/auth/auth.service';
import { FlowSplashScreenService } from '@flow/services/splash-screen';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NotifierComponent],
    providers: [DatePipe]
})

export class AppComponent implements OnInit, OnDestroy {

    deployedVersion = null;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public dialog: MatDialog,
        private _appService: AppService,
        private _authService: AuthService,
        private _flowSplashScreenService: FlowSplashScreenService,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        if (this._authService.isLoggedIn()) {
            // Means that the page was refreshed, the user is already logged in, and needs to retrieve the navigation and permissions data from local storage
            if (this._authService.defaultNavigation.length === 0) {
                const storedPermissions = localStorage.getItem('current_user_permissions');
                if (storedPermissions) {
                    const decodedPermissions = atob(storedPermissions);
                    const permissionsJson = JSON.parse(decodedPermissions);
                    this._authService.setUserPermissions(permissionsJson, true);
                }
                this._flowSplashScreenService.hide();
            }
            this.getCurrentFlowVersion(true)
            setInterval(() => this.getCurrentFlowVersion(), 60 * 60 * 1000);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    verifyOutdatedAppVersion(): void {
        const currentVersionStored = localStorage.getItem('flow_version');
        if (currentVersionStored !== this.deployedVersion) {
            this.openUpdateAppDialog(currentVersionStored);
        }
    }

    openUpdateAppDialog(currentVersionStored): void {
        const dialog = this.dialog.open(UpdateAppDialogComponent, {
            autoFocus: true,
            data: {
                currentVersionStored: currentVersionStored,
                deployedVersion: this.deployedVersion
            },
            panelClass: ['md:min-w-140', 'lg:w-1/2', 'update-app-dialog']
        });
        dialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (result) => {
                if (result) {
                    localStorage.setItem('flow_version', this.deployedVersion);
                    location.reload();
                }
            });
    }

    getCurrentFlowVersion(firstLoad = false): void {
        this._appService.getCurrentFlowVersion()
        .pipe(take(1))
        .subscribe({
            next: (data) => {
                if (data?.version) {
                    this.deployedVersion = data.version;
                    if (firstLoad) {
                        localStorage.setItem('flow_version', this.deployedVersion);
                    } else {
                        this.verifyOutdatedAppVersion();
                    }
                }
            }, error: (error) => {
                console.log('Error Getting Flow Current Version');
            }

        });
    }
}
