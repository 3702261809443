import gql from 'graphql-tag';

export const usersFragment = gql`
    fragment UsersFields on User{
        id
        isAdministrator
        isInside
        isOutside
        firstName
        lastName
        email
        username
    }
`;

export const outsideRepCustomerSplitFragment = gql`
    fragment RepSplitFields on OutsideRepCustomerSplit{
        id
        user{
            ...UsersFields
        }
        splitRate
    }
`;

export const customersLiteFragment = gql`
    fragment CustomersLiteFields on CustomersLite {
        companyName
        customerId
        uuid
    }
`;

export const childCustomersFragment = gql`
    fragment childCustomersFields on Customers {
        companyName
        customerId
    }
`;

export const customerFragment = gql`
    fragment CustomerFields on Customers {
        customerId
        companyName
        contactName
        contactEmail
        contactNumber
        draft
        isParent
        alias
        status
        uuid
        insideRepID {
            id
            isAdministrator
            isInside
            isOutside
            firstName
            lastName
            email
            username
            status
        }
        outsideRepID {
            id
            isAdministrator
            isInside
            isOutside
            firstName
            lastName
            email
            username
            status
        }
        outsideRepCustomerSplits{
            id
            user{
                id
                isAdministrator
                isInside
                isOutside
                firstName
                lastName
                email
                username
                status
            }
            splitRate
        }
        childCustomers {
            ...childCustomersFields
        }
        parentId
    }

    ${childCustomersFragment}

`;

export const findRefinedCustomers = gql`
    query ($page: Int, $perpage: Int, $sortField: String, $sortOrder: Int, $filters: [LandingPageFilter]){
        findRefinedCustomers (page: $page, perpage: $perpage, sortField: $sortField, sortOrder: $sortOrder, filters: $filters){
            totalRows
            rows {
                alias
                companyName
                customerId
                insideRep
                outsideRep
                isParent
                status
            }
        }
    }
`;


export const findCustomersWithNoParent = gql`
    {
        findCustomersWithNoParent {
            companyName
            customerId
        }
    }
`;

export const findCustomerByID = gql`
    query ($customerId: Int!){
        findCustomerById(customerId: $customerId) {
            ...CustomerFields
        }
    }
    ${customerFragment}
`;
export const deleteCustomer = gql`mutation ($customerId: Int!){
    deleteCustomerById(customerId:$customerId)
}`;

export const updateCustomer = gql`
    mutation ($CustomerInput: CustomerInput!){
        updateCustomer(input: $CustomerInput){
            ...CustomerFields
        }
    }
    ${customerFragment}
`;

export const createDraftCustomer = gql`
    mutation {
        createDraftCustomer {
            customerId
        }
    }
`;

export const getUsedCustomers = gql`
    query($id:Int!){
        getUsedCustomers(id:$id)
    }
`;

export const findAllCustomersByParent = gql`
    query ($like: String){
        findAllCustomersByParent(like: $like) {
            parentId
            parentName
            childCustomers {
                ...CustomersLiteFields
            }
        }
    }
    ${customersLiteFragment}
    `

export const findSalesRepsByCustomer = gql`
    query($id:Int!){
        findSalesRepsByCustomer(id:$id) {
            insideRep {
                id
                firstName
                lastName
            }
            outsideRep{
                id
                user{
                    ...UsersFields
                }
                splitRate
            }

        }
    }
    ${usersFragment}
`;

export const findCustomerSearch = gql`
    query ($companyName: String){
        findCustomerSearch(companyName: $companyName) {
            ...CustomerFields
        }
    }
    ${customerFragment}
`;

export const saveCustomerAliases = gql`
    mutation ($customerId: Int!, $alias: [String!]){
        saveCustomerAliases(customerId: $customerId, alias: $alias){
            uuid
            alias
        }
    }
`;
