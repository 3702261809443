import { Injectable, Inject, Optional } from '@angular/core';
import { DateTime } from 'luxon';
import {MAT_LUXON_DATE_ADAPTER_OPTIONS, LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Injectable()
export class CustomLuxonDateAdapter extends LuxonDateAdapter {
    constructor(
        @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
        @Optional() @Inject(MAT_LUXON_DATE_ADAPTER_OPTIONS) private options?: any
    ) {
        super(matDateLocale, options);
    }

    override format(date: DateTime, displayFormat?: string): string {
        if (!date.isValid) return '';

        // Use different formats for display vs API
        if (displayFormat === 'input') {
            return date.toFormat('MM/dd/yyyy'); // UI display format
        }
        return date.toFormat('MM/dd/yyyy'); // Default format for GraphQL
    }

    override parse(value: any, parseFormat: string | string[]): DateTime | null {
        if (!value) return null;

        // Handle user input from different possible formats
        if (typeof value === 'string') {
            let parsedDate = DateTime.fromFormat(value, 'Mdyy', this.options).toUTC();
            if (!parsedDate.isValid) {
                parsedDate = DateTime.fromFormat(value, 'MM/dd/yyyy', this.options).toUTC();
            }
            if (parsedDate.isValid) {
                return parsedDate;
            }
        }
        return super.parse(value, parseFormat);
    }
}
