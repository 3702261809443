import { Injectable, Inject, Optional } from '@angular/core';
import { DateTime } from 'luxon';
import {MAT_LUXON_DATE_ADAPTER_OPTIONS, LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Injectable()
export class CustomLuxonDateAdapter extends LuxonDateAdapter {

    constructor(

        @Optional() @Inject(MAT_LUXON_DATE_ADAPTER_OPTIONS) private options?,
        // @ts-ignore
        @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string
    ) {
        super(matDateLocale, options);
    }

    format(date: DateTime, displayFormat: Object): string {
        // Customize the format for displaying the date
        return date.toFormat('MM/dd/yyyy');
    }

    parse(value: any, parseFormat: string | string[]): DateTime | null {
        // Customize the parsing logic to handle 'MMddyyyy' format
        if (typeof value === 'string') {
            const parsedDate = DateTime.fromFormat(value, 'Mdyy', this.options).toUTC();
            if (parsedDate.isValid) {
                return parsedDate;
            }
        }
        return super.parse(value, parseFormat);
    }
}
