import { Component, ElementRef, EventEmitter, inject, input, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HotkeyDirective } from '@flow/directives/hotkeys/hotkey.directive';
import { SearchService } from '@flow/services/search/search.service';
import { MatButtonModule } from '@angular/material/button';
import { SiteWideSearch } from '@flow/types';

@Component({
  selector: 'app-search-result-item',
  imports: [
    MatIconModule,
    HotkeyDirective,
    MatButtonModule
  ],
  templateUrl: './search-result-item.component.html',
  styleUrl: './search-result-item.component.scss',
  standalone: true,
  
})
export class SearchResultItemComponent {

  result = input<SiteWideSearch>();
  isHistory = input<boolean>(false);
  @Output() itemSelected = new EventEmitter<SiteWideSearch>();
  @Output() removeItem = new EventEmitter<void>();
  
  @ViewChild('container') containerRef!: ElementRef<HTMLDivElement>;
  private searchService = inject(SearchService);

  // Icon getter
  getIconForType(type: string): string {
    return this.searchService.getIconForType(type);
  }

  focus() {
    this.containerRef?.nativeElement.focus();
  }

  hasFocus(): boolean {
    return document.activeElement === this.containerRef?.nativeElement;
  }

  select() {
    if (!this.hasFocus()) {
      return
    }
    this.itemSelected.emit(this.result());
  }

  remove() {
    this.removeItem.emit();
  }

}


