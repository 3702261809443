import {HttpClient, HttpHeaders, provideHttpClient} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {MAT_LUXON_DATE_ADAPTER_OPTIONS} from '@angular/material-luxon-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading} from '@angular/router';
import {appRoutes} from 'app/app.routes';
import {provideAuth} from 'app/core/auth/auth.provider';
import {provideIcons} from 'app/core/icons/icons.provider';
import {Apollo, APOLLO_OPTIONS} from "apollo-angular";
import {HttpLink} from "apollo-angular/http";
import {InMemoryCache, ApolloClientOptions} from "@apollo/client/core";
import {setContext} from "@apollo/client/link/context";
import {environment} from "../environments/environment";
import {MatBottomSheet, MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {provideLottieOptions} from 'ngx-lottie';
import {APP_BASE_HREF} from "@angular/common";
import {CustomLuxonDateAdapter} from "./shared/services/custom-luxon-date-adapter";
import {provideMarkdown} from 'ngx-markdown';
import {provideFlow} from "../@flow";


const basic = setContext((operation, context) => ({
    headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: 'charset=utf-8'
    }
}));

const uri = environment.apiUrl;

export const appConfig: ApplicationConfig = {
    providers: [
        provideMarkdown(),
        provideLottieOptions({
            player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
        }),
        provideAnimations(),
        provideHttpClient(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: CustomLuxonDateAdapter
        },
        // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM/dd/yyyy',
                },
                display: {
                    dateInput: 'MM/dd/yyyy',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                }
            },
        },
        {
            provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true
            },

        },
        MatBottomSheetModule,
        MatBottomSheet,

        // Flow
        provideAuth(),
        provideIcons(),
        provideFlow({
            flow: {
                layout: 'main-layout',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        importProvidersFrom(HttpClient),
        {
            provide: APOLLO_OPTIONS,
            useFactory: (
                httpLink: HttpLink,
            ): ApolloClientOptions<unknown> => ({
                link: httpLink.create({
                    uri: uri,
                }),
                cache: new InMemoryCache({
                    addTypename: false,
                    resultCaching: false,
                }),
                connectToDevTools: true
            }),
            deps: [HttpLink],
        },
        Apollo,
    ],
};

export function getBaseLocation() {

    const basePath: string = localStorage.getItem('current_tenant') ? localStorage.getItem('current_tenant') : ''; // Default: my-account
    return '/' + basePath;
}
