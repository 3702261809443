<div class="relative flex flex-col w-full h-full">

    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button [matDialogClose]="'cancelled'" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'" class="text-secondary"></mat-icon>
        </button>
    </div>

    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
        <div class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
            <i class="text-primary pi pi-info-circle text-3xl"></i>
        </div>
        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
            <div class="text-xl leading-6 font-medium">
                New Version of Flow Available
            </div>
            <div class="text-secondary">
                Flow has recently been updated to add new features and make the app better for you. You are currently using an out of date version. Click the update button below and we will handle the rest.
            </div>
            <div class="w-full pt-18 pb-20 grid grid-cols-2">
                <div class="text-center">
                    <div class="text-lg leading-6 font-medium">
                        Your Version
                    </div>
                    <div class="mt-6 font-extrabold text-2xl leading-6 text-primary">
                        {{dialogData?.currentVersionStored}}
                    </div>
                </div>
                <div class="text-center">
                    <div class="text-lg leading-6 font-medium">
                        Latest version
                    </div>
                    <div class="mt-6 font-extrabold text-2xl leading-6 text-primary">
                        {{dialogData?.deployedVersion}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10 rounded-br-2xl rounded-bl-2xl">
        <button [matDialogClose]="" mat-stroked-button>
            Maybe Later
        </button>
        <button [color]="'primary'" [matDialogClose]="true" mat-flat-button>
            Update
        </button>
    </div>
</div>
