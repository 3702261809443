import gql from 'graphql-tag';
import { customerFragment } from '../customers/customers.query';
import {unitOfMeasureFragment} from "../unit-of-measure/unit-of-measure.query";

export const factoriesFragment = gql`
    fragment FactoriesFields on Factories {
        factoryId
        title
        accountNumber
        phone
        email
        status
        shipTime
        freightTerms
        paymentTerms
        paysShipping
        baseCommission
        commissionDiscount
        overallDiscount
        discountType
        commissionPaymentTime
        leadTime
        additionalInformation
        externalPaymentTerms
        draft
        uuid
    }
`;

export const categoriesFragment = gql`
    fragment CategoriesFields on ProductCategory {
        uuid
        title
        uuid
        factory {
            ...FactoriesFields
        }
        commissionRate
    }
    ${factoriesFragment}
`;

export const quantityPricingFragment = gql`
    fragment QuantityPricingFields on QuantityPricing {
        id
        quantityLow
        quantityHigh
        unitPrice
    }
`;

// Categories

export const productFragment = gql`
    fragment ProductFields on Products {
        productId
        factory {
            factoryId
            title
        }
        category {
           ...CategoriesFields
        }
        factoryPartNumber
        description
        status
        ean
        imageURL
        commissionRate
        minOrderQty
        listPrice
        unitPrice
        basePrice
        unitOfMeasures{
            ...UnitOfMeasureFields
        }
        alternateEAN
        caseUPC
        indUPC
        approvalNeeded
        approvalDate
        approvalComments
        leadTime
        commissionDiscountRate
        discountRate
        visibility
        uuid
        quantityPricing {
            ...QuantityPricingFields
        }
    }
    ${categoriesFragment}
    ${quantityPricingFragment}
    ${unitOfMeasureFragment}
`;

export const findProductById = gql`query ($id: Int!) {
    findProductById(id: $id) {
        ...ProductFields
    }

}
${productFragment}
`;



// Mutation

export const saveNewProduct = gql`
        mutation($Input: ProductsInput ){
        saveProduct(input:$Input) {
            ...ProductFields
        }

    }
    ${productFragment}
`;

// Delete

export const deleteMutation = gql`
    mutation($id: Int! ){
        deleteProduct(id: $id)
    }
`;

export const deleteProducts = gql`
    mutation ($ids: [Int]) {
        deleteProducts(ids: $ids) {
            success
            message
            id
        }
    }
`;


// Update Product Mutation
export const updateProduct = gql`
    mutation updateProduct($input: ProductsInput) {
        updateProduct(input: $input) {
            ...ProductFields
        }

    }
    ${productFragment}
`;

export const findRefinedProducts = gql`
    query ($page: Int, $perpage: Int, $sortField: String, $sortOrder: Int, $filters: [LandingPageFilter]){
        findRefinedProducts (page: $page, perpage: $perpage, sortField: $sortField, sortOrder: $sortOrder, filters: $filters){
            rows {
                productId
                partNumber
                factory
                category
                description
                commissionRate,
                cost,
                uom,
                visibility
            }
            totalRows
        }
    }
`;

export const getUsedProducts = gql`
    query($Id:Int){
        getUsedProducts(id:$Id)
    }
`;

export const saveCategory = gql`
    mutation($Input: ProductCategoryInput ){
        saveCategory(input:$Input) {
            uuid
            title
            commissionRate
        }
    }
`;

export const deleteCategory = gql`
    mutation($id: Int! ){
        deleteCategory(id: $id)
    }
`;

export const updateCategory = gql`
    mutation updateCategory($input: ProductCategoryInput) {
        updateCategory(input: $input) {
            uuid
            title
            commissionRate
        }
    }
`;

export const saveCustomerPartNumber = gql`
    mutation saveCustomerPartNumber($input: CustomerPartNumberInput!) {
        saveCustomerPartNumber(input: $input) {
            customer {
                ...CustomerFields
            }
            customerPartNumber
            unitPrice
            commissionRate
            productCpnId
        }
    }
    ${customerFragment}
`;

export const updateCustomerPartNumber = gql`
    mutation updateCustomerPartNumber($cpnInput: CustomerPartNumberInput!) {
        updateCustomerPartNumber(cpnInput: $cpnInput) {
            customer {
                ...CustomerFields
            }
            customerPartNumber
            productCpnId
            unitPrice
            commissionRate
            productCpnId
        }
    }
    ${customerFragment}
`;

export const deleteCustomerPartNumber = gql`
    mutation($id: Int! ){
        deleteCustomerPartNumber(id: $id)
    }
`;

export const findCPNsByProductId = gql`
    query ($id: Int!) {
        findCPNsByProductId(id: $id) {
            customer {
                ...CustomerFields
            }
            customerPartNumber
            productCpnId
            unitPrice
            commissionRate
        }
    }
    ${customerFragment}
`;

export const findProductsByFactoryAndCustomer = gql`
    query ($factoryId: Int, $customerId: Int, $partNumber: String) {
        findProductsByFactoryAndCustomer(factoryId: $factoryId, customerId: $customerId, partNumber: $partNumber) {
            productId
            factoryPartNumber
            leadTime
            unitPrice
            commissionRate
            commissionDiscountRate
            discountRate
        }
    }
`;

export const findProductsByFactoryAndCustomers = gql`
    query ($factoryId: Int, $customerIds: [Int], $partNumber: String) {
        findProductsByFactoryAndCustomers(factoryId: $factoryId, customerIds: $customerIds, partNumber: $partNumber) {
            productId
            factoryPartNumber
            leadTime
            unitPrice
            commissionRate
        }
    }
`;

export const findProductsByFactoryId  = gql`
    query ($factoryId: Int) {
        findProductsByFactoryId(factoryId: $factoryId) {
            productId
            factoryPartNumber
        }
    }
`;

export const findCPNsByProductAndCustomer  = gql`
    query ($productId: Int!, $customerId: Int) {
        findCPNsByProductAndCustomer (productId: $productId, customerId: $customerId) {
            productCpnId
            customer {
                ...CustomerFields
            }
            customerPartNumber
            unitPrice
            commissionRate
        }
    }
    ${customerFragment}
`;

export const findCategoryByFactory = gql`
    query ($factoryId: Int) {
        findCategoryByFactory(factoryId: $factoryId){
            ...CategoriesFields
        }
    }
    ${categoriesFragment}
`;
export const categoriesQuery = gql`
    {
        findAllProductCategories{
            ...CategoriesFields
        }
    }
    ${categoriesFragment}
`;

export const findUnitPriceByQuantity = gql`
    query ($productId: Int, $quantity: Int){
        findUnitPriceByQuantity(productId: $productId, quantity: $quantity)
    }
`;

export const findUnitPricesByProduct = gql`
    query($productId: Int){
        findUnitPricesByProduct(productId: $productId){
            ...QuantityPricingFields
            products{
                ...ProductFields
            }
        }
    }
    ${quantityPricingFragment}
    ${productFragment}
`;

export const updateProductCommissionByCategory = gql`
    mutation ($categoryUuid: ID, $commissionRate: Float){
        updateProductCommissionByCategory(categoryUuid:$categoryUuid, commissionRate:$commissionRate) {
            ...ProductFields
        }
    }
    ${productFragment}
`;

export const saveProductAliases = gql`
    mutation ($productId: Int!, $alias: [String!]){
        saveProductAliases(productId: $productId, alias: $alias){
            uuid
            alias
        }
    }
`;

