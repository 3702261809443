import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { findNotifierSettingsByUserId } from 'app/modules/admin/admin-settings/admin-settings.query';
import { NotificationSettings } from 'app/modules/admin/admin-settings/admin-settings.type';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NotifierService {

    // Notification settings
    notificationSettings: NotificationSettings[] = [];
    locations = [
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right'
    ]
	alerts = [
		{
			alertType: 'success',
			id: 1,
            locations: this.locations
		},
		{
			alertType: 'info',
			id: 2,
            locations: this.locations
		},
		{
			alertType: 'error',
			id: 3,
            locations: this.locations
		}
	]

    // Notification open
    notificationSubject = new Subject<any>();
    notificationState = this.notificationSubject.asObservable();

	constructor(
        private apollo: Apollo
    ) { }

    open(type: 'success' | 'error' | 'info', message: string, title?: string, duration = type === 'success' ? 5000 : 10000, timestamp = 'a few seconds ago'): void {
        this.notificationSubject.next({ type, message, title, duration, timestamp });
    }

    findNotificationSettings(userId: string): Observable<NotificationSettings[]> {
        return this.apollo
            .query<{ findNotifierSettingsByUserId: NotificationSettings[] }>({
                query: findNotifierSettingsByUserId,
                variables: {
                    userId: userId
                },
                fetchPolicy: 'no-cache'
            }).pipe(
                map(result => result.data.findNotifierSettingsByUserId),
                catchError(error => throwError(error))
            );
    }
}
