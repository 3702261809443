import gql from 'graphql-tag';

export const findUserPermissions = gql`
    query {
        findUserPermissions {
            navigationData {
                id
                title
                subtitle
                type
                icon
                link
                children {
                    id
                    title
                    subtitle
                    type
                    icon
                    link
                }
            }
            permissions {
                resource
                privileges
            }
            roleOptions {
                allowCommissionsVisibility
            }
        }
    }
`;
