<ng-container *ngIf="!child">
    <div
        [ngClass]="{'flow-horizontal-navigation-menu-active': trigger.menuOpen,
                 'flow-horizontal-navigation-menu-active-forced': item.active}"
        [matMenuTriggerFor]="matMenu"
        (onMenuOpen)="triggerChangeDetection()"
        (onMenuClose)="triggerChangeDetection()"
        #trigger="matMenuTrigger">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
    </div>
</ng-container>

<mat-menu
    class="flow-horizontal-navigation-menu-panel"
    [overlapTrigger]="false"
    #matMenu="matMenu">

    <ng-container *ngFor="let item of item.children; trackBy: trackByFn">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

            <!-- Basic -->
            <ng-container *ngIf="item.type === 'basic'">
                <div
                    class="flow-horizontal-navigation-menu-item"
                    [disabled]="item.disabled"
                    mat-menu-item>
                    <flow-horizontal-navigation-basic-item
                        [item]="item"
                        [name]="name"></flow-horizontal-navigation-basic-item>
                </div>
            </ng-container>

            <!-- Branch: aside, collapsable, group -->
            <ng-container *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'">
                <div
                    class="flow-horizontal-navigation-menu-item"
                    [disabled]="item.disabled"
                    [matMenuTriggerFor]="branch.matMenu"
                    mat-menu-item>
                    <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
                    <flow-horizontal-navigation-branch-item
                        [child]="true"
                        [item]="item"
                        [name]="name"
                        #branch></flow-horizontal-navigation-branch-item>
                </div>
            </ng-container>

            <!-- Divider -->
            <ng-container *ngIf="item.type === 'divider'">
                <div
                    class="flow-horizontal-navigation-menu-item"
                    mat-menu-item>
                    <flow-horizontal-navigation-divider-item
                        [item]="item"
                        [name]="name"></flow-horizontal-navigation-divider-item>
                </div>
            </ng-container>

        </ng-container>

    </ng-container>

</mat-menu>

<!-- Item template -->
<ng-template
    let-item
    #itemTemplate>

    <div
        class="flow-horizontal-navigation-item-wrapper"
        [class.flow-horizontal-navigation-item-has-subtitle]="!!item.subtitle"
        [ngClass]="item.classes?.wrapper">

        <div
            class="flow-horizontal-navigation-item"
            [ngClass]="{'flow-horizontal-navigation-item-disabled': item.disabled,
                         'flow-horizontal-navigation-item-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''">

            <!-- Icon -->
            <ng-container *ngIf="item.icon">
                <mat-icon
                    class="flow-horizontal-navigation-item-icon"
                    [ngClass]="item.classes?.icon"
                    [svgIcon]="item.icon"></mat-icon>
            </ng-container>

            <!-- Title & Subtitle -->
            <div class="flow-horizontal-navigation-item-title-wrapper">
                <div class="flow-horizontal-navigation-item-title">
                    <span [ngClass]="item.classes?.title">
                        {{item.title}}
                    </span>
                </div>
                <ng-container *ngIf="item.subtitle">
                    <div class="flow-horizontal-navigation-item-subtitle text-hint">
                        <span [ngClass]="item.classes?.subtitle">
                            {{item.subtitle}}
                        </span>
                    </div>
                </ng-container>
            </div>

            <!-- Badge -->
            <ng-container *ngIf="item.badge">
                <div class="flow-horizontal-navigation-item-badge">
                    <div
                        class="flow-horizontal-navigation-item-badge-content"
                        [ngClass]="item.badge.classes">
                        {{item.badge.title}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-template>
