import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';
import {
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
} from '@angular/material/sidenav';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterLink,
    RouterOutlet,
} from '@angular/router';
import { FlowDrawerMode } from '@flow/components/drawer';
import { FlowFullscreenComponent } from '@flow/components/fullscreen/fullscreen.component';
import { FlowConfigService } from '@flow/services/config/config.service';
import { FlowMediaWatcherService } from '@flow/services/media-watcher/media-watcher.service';
import { filter, Subject, Subscription, takeUntil, timer, pipe } from 'rxjs';

import { NgIf } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MenuMostUsedComponent } from '@app/routes/admin/users/components/menu-most-used/menu-most-used.component';
import { MenuUserComponent } from '@app/routes/admin/users/components/menu-user/menu-user.component';
import { MessageCenterComponent } from '@app/routes/admin/users/components/message-center/message-center.component';
import { FlowLoadingBarComponent } from '@flow/components/loading-bar';
import { FlowbotDrawerService, StorageService } from '@flow/services';
import {
    FlowbotButtonComponent
} from '@app/routes/admin/flowbot/components/chat/flowbot-button/flowbot-button.component';
import { FlowbotChatComponent } from '@app/routes/admin/flowbot/components/chat/flowbot-chat/flowbot-chat.component';
import {
    FlowNavigationItem,
    FlowNavigationService,
    FlowVerticalNavigationComponent,
} from '@flow/components/navigation';
import { GraphqlService } from '@flow/services/graphql/graphql.service';
import { FINDPLANBYTENANTNAME } from '@flow/queries';
import { take } from 'rxjs/operators';
import { PlanType } from '@flow/types';
import { crmNav, fileManagerNav, fullNav } from '@app/layout/layouts/main/temp-nav.types';
import {
    FlowbotToolbarComponent
} from '@app/routes/admin/flowbot/components/chat/flowbot-toolbar/flowbot-toolbar.component';
import {
    AdminHeaderButtonComponent
} from '@app/routes/admin/admin-settings/components/admin-header-button/admin-header-button.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchComponent } from '@flow/components/search/search.component';

@Component({
    selector: 'main-layout',
    standalone: true,
    imports: [
        RouterOutlet,
        FlowFullscreenComponent,
        MenuUserComponent,
        MessageCenterComponent,
        MenuMostUsedComponent,
        FlowbotButtonComponent,
        RouterLink,
        MatDrawerContainer,
        MatDrawer,
        MatDrawerContent,
        NgIf,
        FlowVerticalNavigationComponent,
        FlowbotChatComponent,
        FlowLoadingBarComponent,
        MatIconButton,
        MatIcon,
        FlowbotToolbarComponent,
        AdminHeaderButtonComponent
    ],
    templateUrl: './main-layout.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .main-nav.flow-vertical-navigation-hover{
                width: 280px !important;
                min-width: 280px !important;
                max-width: 280px !important;
            }
        `,
    ],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FlowNavigationItem[];
    navigationAppearance: 'default' | 'dense' = 'dense';
    drawerMode: FlowDrawerMode;
    drawerOpened: boolean;
    isTargetRoute = false;
    logo: string = 'images/logo-dark.png';
    plan: PlanType;
    dialogSearchOpened: boolean = false;
    searchInput = signal<string>(''); // Signal to receive the user’s search input in the universal search dialog to pass it to the flowbot.


    protected scheme: 'dark' | 'light';
    private routeSub: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private drawerSubscription: Subscription;
    private _storageService = inject(StorageService);
    private _graphql = inject(GraphqlService);
    readonly dialog = inject(MatDialog);
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _flowMediaWatcherService: FlowMediaWatcherService,
        private _flowNavigationService: FlowNavigationService,
        private _flowDrawerService: FlowbotDrawerService,
        private cdr: ChangeDetectorRef
    ) {
        this.drawerOpened = false;


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.logo = this.logo =
            'images/logo-' + this._storageService.get('scheme') + '.png';
        this.waitForPlanType().subscribe(() => {
            this.initializePlan();
        });


        this.cdr.detectChanges();
        this._flowMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            // @ts-ignore
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');

                // Change the navigation appearance
                this.navigationAppearance = this.isScreenSmall
                    ? 'default'
                    : 'dense';
                this.cdr.detectChanges();
            });

        this.drawerSubscription =
            this._flowDrawerService.drawerState$.subscribe(
                (isOpen: boolean) => {
                    console.log(isOpen);
                    this.drawerOpened = isOpen;
                    this.cdr.detectChanges();
                }
            );
        // Check on initial load or refresh
        this.checkRoute();

        // Listen to route changes
        this.routeSub = this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.checkRoute();
            });
    }

    waitForPlanType() {
        return timer(0, 100).pipe( // Checks every 100ms
            filter(() => !!this._storageService.get('plan_type')), // Proceeds only when plan_type exists
            take(1)
        );
    }

    initializePlan() {
        this.plan = PlanType[this._storageService.get('plan_type') as keyof typeof PlanType];

        if (!this.plan) {
            this._graphql.executeQuery(FINDPLANBYTENANTNAME, { tenantName: localStorage.getItem('tenant') })
                .pipe(take(1))
                .subscribe((result: any) => {
                    const plan = result.findPlanByTenantName;
                    this._storageService.set('plan_type', plan);
                    this.plan = PlanType[plan as keyof typeof PlanType]; // Update plan
                    this.setNavigation();
                });
        } else {
            this.setNavigation();
        }
    }

    setNavigation() {
        switch (this.plan) {
            case PlanType.PREMIUM:
                this.navigation = fullNav;
                break;
            case PlanType.STANDARD:
                this.navigation = crmNav;
                break;
            case PlanType.BASIC:
                this.navigation = fileManagerNav;
                break;
            default:
                this.navigation = fileManagerNav;
        }
        this.cdr.detectChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._flowNavigationService.getComponent<FlowVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this.navigationAppearance =
            this.navigationAppearance === 'default' ? 'dense' : 'default';
    }

    /**
     * Toggle the drawer open
     */
    toggleDrawerOpen(): void {
        this._flowDrawerService.toggleDrawer();
    }

    openSearchDialog(): void {
        if (this.dialogSearchOpened) {
            return;
        }
        this.dialogSearchOpened = true;
        const dialogRef = this.dialog.open(SearchComponent, {
            panelClass: ['custom-dialog-padding'],
            width: '1200px', 
            maxWidth: '95vw', 
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(async (result) => {
            if (result?.action === 'openFlowBot') {
                this.searchInput.set(result?.query);
                this.toggleDrawerOpen();
            }
            this.dialogSearchOpened = false;
        });
    }

    private checkRoute(): void {
        //this.isTargetRoute =
        //this._activatedRoute.snapshot?._routerState === 'flowbot';
    }

    navigateToFullChat() {
        this._router.navigate(['/flowbot/chat']).then(result => {
            this._flowDrawerService.toggleDrawer();
        });
    }
}
