import { Component, OnInit } from '@angular/core';
import { NotifierService } from './notifier.service';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Notifier } from 'app/shared/types/notifier.type';
import { NotificationSettings } from 'app/modules/admin/admin-settings/admin-settings.type';
import { take } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'app-notifier',
    templateUrl: './notifier.component.html',
    styleUrls: ['./notifier.component.scss'],
    imports: [
        NgClass,
        NgIf,
        NgFor,
        MatButtonModule,
        MatIconModule,
        NgStyle
    ],
    standalone: true
})
export class NotifierComponent implements OnInit {

    notifications: Notifier[] = [];

    type: 'success' | 'error' | 'info';
    title: string;
    message: string;
    duration: number;
    timestamp: string;
    isVisible: boolean = false;
    timestampCounter = 5;

    constructor(
        private _notifier: NotifierService,
        private _authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        if (this._authService.isLoggedIn()) {
            this.getNotificationSettings();
        }
        this._notifier.notificationState.subscribe((data) => {

            // Notification Settings
            let notificationLocation = '';
            if (this._notifier.notificationSettings && this._notifier.notificationSettings.length > 0) {
                const alertId = this.findAlertIdByType(data.type);
                const currentNotificationConfig = this.findNotificationSavedSettingsById(alertId);
                if (!currentNotificationConfig?.visibility) {
                    return;
                } else {
                    notificationLocation = this._notifier.locations[currentNotificationConfig.location - 1];
                }
            } else {
                // Default notification setting - top right
                notificationLocation = this._notifier.locations[1];
            }

            const title = data.title ? data.title :
                data.type === 'success' ? 'Success!' :
                data.type === 'error' ? 'Error!' :
                'Attention!';

            const numberOfTopNotifications = this.notifications.filter(item => item.notificationSideLocation === 'top-left' || item.notificationSideLocation === 'top-right').length;
            const numberOfBottomNotifications = this.notifications.filter(item => item.notificationSideLocation === 'bottom-left' || item.notificationSideLocation === 'bottom-right').length;
            const notifierTopDistance = notificationLocation === 'top-left' || notificationLocation === 'top-right' ? this.calculateDistance(numberOfTopNotifications) : 'auto';
            const notifierBottomDistance = notificationLocation === 'bottom-left' || notificationLocation === 'bottom-right' ? this.calculateDistance(numberOfBottomNotifications) : 'auto';

            const notification: Notifier = {
                type: data.type,
                message: data.message,
                title: title,
                timestamp: data.timestamp,
                isVisible: true,
                duration: data.duration,
                timestampCounter: 5,
                notificationSideLocation: notificationLocation,
                notifierTopDistance: notifierTopDistance,
                notifierBottomDistance: notifierBottomDistance,
                uuid: crypto.randomUUID()
            };
            this.notifications.push(notification);

            setTimeout(() => {
                this.clearNotifier(notification);
            }, data.duration);

            if (data.duration >= 5000) {
                setTimeout(() => {
                    let intervalId = setInterval(() => this.updateTimestamp(notification), 1000);
                    setTimeout(() => clearInterval(intervalId), data.duration - 5000);
                }, 5000);
            }
        });
    }

    clearNotifier(notification): void {
        notification.isVisible = false;

        // Used to control the style needed for the fade out animation
        notification.updateAnimationPosition = true;

        // The notifier should clear the data inside only when the fadeOut ends;
        setTimeout(() => {
            const index = this.notifications.findIndex(item => item.uuid === notification.uuid);
            if (index !== -1) {
                this.notifications.splice(index, 1);
                if (notification.notificationSideLocation === 'top-left' || notification.notificationSideLocation === 'top-right') {
                    this.updateTopDistances();
                } else if (notification.notificationSideLocation === 'bottom-left' || notification.notificationSideLocation === 'bottom-right') {
                    this.updateBottomDistances();
                }
            }
        }, 800);
    }

    updateTopDistances(): void {
        this.notifications.forEach((item, index) => {
            if (item.notificationSideLocation === 'top-left' || item.notificationSideLocation === 'top-right') {
                item.notifierTopDistance = this.calculateDistance(index);
            }
        });
    }

    updateBottomDistances(): void {
        this.notifications.forEach((item, index) => {
            if (item.notificationSideLocation === 'bottom-left' || item.notificationSideLocation === 'bottom-right') {
                item.notifierBottomDistance = this.calculateDistance(index);
            }
        });
    }

    calculateDistance(index: number): string {
        if (index === 0) {
            return '120px';
        } else {
            // 20px of martin between them
            const topPosition = 120 + (index * 170);
            return `${topPosition}px`;
        }
    }

    updateTimestamp(notification): void {
        notification.timestampCounter++;
        notification.timestamp = notification.timestampCounter + ' seconds ago';
    }

    getNotificationSettings(): void {
        // Stores the values on app.service to be accessed globally
        this._notifier.findNotificationSettings(localStorage.getItem('current_user'))
            .pipe(take(1))
            .subscribe((result: NotificationSettings[]) => {
                if (result) {
                    this._notifier.notificationSettings = result;
                }
            });
    }

    findAlertIdByType(alertType: string): number | undefined {
        const foundAlert = this._notifier.alerts.find(alert => alert.alertType === alertType);
        return foundAlert?.id;
    }

    findNotificationSavedSettingsById(id: number): NotificationSettings {
        return this._notifier.notificationSettings.find(setting => +setting.alertType === id);
    }
}
