import {inject} from '@angular/core';
import {CanActivateChildFn, CanActivateFn, Router} from '@angular/router';
import {of, switchMap} from 'rxjs';
import {AuthService} from 'app/core/auth/auth.service';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);

    // Check the authentication status
    return authService.check().pipe(
        switchMap((authenticated) => {
            // If the user is not authenticated...
            if (!authenticated) {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
                return of(urlTree);
            }

            // If authenticated, retrieve the tenant
            const tenant = authService.tenant;
            const currentUrl = state.url;

            // Check if the tenant is already in the URL to prevent a loop
            if (tenant && !currentUrl.startsWith(`/${tenant}`)) {
                const newUrl = `/${tenant}${currentUrl}`;
                const urlTree = router.parseUrl(newUrl);
                return of(urlTree);
            }

            // Allow access if the tenant is already in the URL or no tenant-specific logic is needed
            return of(true);
        })
    );
};
