import { Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
    selector: 'app-admin-header-button',
    imports: [
        MatIcon,
        MatIconButton,
    ],
    templateUrl: './admin-header-button.component.html',
    standalone: true,
    styleUrl: './admin-header-button.component.scss',
})
export class AdminHeaderButtonComponent {
    private _router = inject(Router);

    goToAdminSettings() {
        this._router.navigate(['/administrator']);
    }
}
