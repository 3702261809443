import gql from 'graphql-tag';

export const siteWideSearch = gql`
    query ($searchString:String){
        siteWideSearch(title:$searchString){
            title
            id
            type
            link
            linkId
        }
    }
`;
