<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <mat-icon
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-2 w-2 h-2 rounded-full bg-green-500"
        ></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{userEmail}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="goToSettings()">
        <mat-icon [svgIcon]="'mat_outline:settings'" style="font-size: 1.250rem; margin-right: 12px;"></mat-icon>
        <span>Settings</span>
    </button>
    <button
        mat-menu-item
        (click)="goToSupport()">
        <i class="pi pi-question-circle" style="font-size: 1.250rem; margin-left: 2px; margin-right: 14px;"></i>
        <span>Support</span>
    </button>
    <button
        mat-menu-item
        (click)="goRoadmap()">
        <mat-icon style="font-size: 1.250rem; margin-left: 2px; margin-right: 14px;" [svgIcon]="'heroicons_outline:map'"></mat-icon>
        <span>flowIdeas (roadmap)</span>
    </button>
    <button
        mat-menu-item
        (click)="goSiteStatus()">
        <mat-icon style="font-size: 1.250rem; margin-left: 2px; margin-right: 14px;" [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
        <span>App Status</span>
    </button>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>
